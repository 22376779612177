import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib';
import { v4 as uuidv4 } from 'uuid';
import { isNaN } from 'lodash';
import { StringOrNumber } from '@/types';
import i18n from '@/i18';
import { TOAST_MESSAGES } from '@/constants';

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;
export const isNullOrUndefined = (value: any) => value === null || value === undefined;

export function convertToFormData<T extends Record<string, any>>(data: T): FormData {
  const formData = new FormData();

  // Add each form field to the FormData object
  if (typeof data === 'object') {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (!isNullOrUndefined(value)) {
          if (Array.isArray(value)) {
            value.forEach((item) => formData.append(key, item));
          } else {
            formData.append(key, value as string | Blob);
          }
        }
      }
    }
  }

  return formData;
}

export const langLabel = (text: string, language: 'ar' | 'en') => `${text} ${i18n.t(language)}`;
export const optionalLabel = (text: string) => `${text} ${i18n.t('optional')}`;

// check file type  based on props
export const checkFileType = (fileType: string, fileTypes: string[]) => {
  if (!fileTypes || (fileTypes && fileTypes[0] === '*')) {
    return true;
  }
  if (fileTypes && Array.isArray(fileTypes) && fileTypes.length > 0 && fileTypes.indexOf(fileType) !== -1) {
    return true;
  }
  return false;
};

// validate file size based on props
export const isValidFileSize = (fileSize: number, maxSizeInMB: number): boolean => {
  if (maxSizeInMB) {
    return fileSize / 1024 / 1024 < maxSizeInMB;
  }
  return true;
};

export const validateFile = (file: RcFile, fileTypes: string[], maxSizeInMB: number) => {
  const isValidFileType = checkFileType(file.type, fileTypes as string[]);
  const isValidSize = isValidFileSize(file.size, maxSizeInMB as number);

  let errorMessage;
  if (!isValidSize) {
    errorMessage = i18n.t('fileSizeExceedsTheLimit', { fileSize: `${maxSizeInMB}MB` });
  }
  if (!isValidFileType) {
    errorMessage = `${i18n.t('fileTypeNotSupported', {
      supportedTypes: fileTypes.join(', '),
    })}`;
  }
  if (errorMessage) {
    message.destroy();
    message.error(errorMessage);
  }
  return isValidFileType && isValidSize;
};

export const getBase64 = (file: RcFile, callback: (readedFile: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    if (callback) {
      callback(reader.result);
    }
  });
  reader.readAsDataURL(file);
};
export const removeNullUndefined = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined));

export const getOriginFileObjectFromFiles = (files: UploadFile | UploadFile[]) => {
  if (Array.isArray(files)) {
    const finalFiles = [];
    files.forEach((fileItem) => {
      if (fileItem && fileItem.originFileObj) {
        finalFiles.push(fileItem.originFileObj);
      }
    });
    return finalFiles;
  }
};

export const makeFileObj = (url: string) => ({
  uid: uuidv4(),
  name: uuidv4(),
  status: 'done' as UploadFileStatus,
  url,
});

export function updateObjStatusInArrById<T extends { id: number; is_active: 'active' | 'inactive' }>(
  id: number,
  is_active: 'active' | 'inactive',
  arr: T[],
  withStatusChange: boolean = true,
) {
  return arr.map((item) => {
    if (item.id === id) {
      // eslint-disable-next-line no-nested-ternary
      return { ...item, is_active: withStatusChange ? (is_active === 'active' ? 'inactive' : 'active') : status };
    }
    return item;
  });
}

export const isFile = (item: any) => item instanceof File;

export const showSuccessMessage = (name: string, type: 'add' | 'delete' | 'update', text?: string) => {
  message.destroy();
  switch (type) {
    case 'delete':
      message.success(text ?? i18n.t(TOAST_MESSAGES.DELETE_MESSAGE_SUCCESS(i18n.t(name))));
      break;
    case 'add':
      message.success(text ?? i18n.t(TOAST_MESSAGES.ADD_SUCCESS(i18n.t(name))));
      break;
    case 'update':
      message.success(text ?? i18n.t(TOAST_MESSAGES.UPDATE_SUCCESS(i18n.t(name))));
      break;
    default:
      break;
  }
};

export const formatNumber = (num: StringOrNumber): string => {
  if (num === null || num === undefined || num === '') {
    return '';
  }

  const numericValue = Number(num);
  if (isNaN(numericValue)) {
    return ''; // Handle invalid number inputs
  }

  // Format number with commas for thousands
  const formattedNumber = numericValue.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
};
export const checkDuplicatedIds = (currentList: any[]) => {
  const ids = currentList?.map((item) => item.id);
  return new Set(ids).size !== ids?.length;
};

type Item = Record<string, any>;

export function mapItemsToLabelValue<T extends Item>(
  items: T[],
  labelKey: keyof T,
  valueKey: keyof T,
): { label: string; value: string | number }[] {
  return items.map((item) => ({
    label: String(item[labelKey]),
    value: item[valueKey],
  }));
}

export const isThereDuplications = (array: any[], key: string) => {
  const duplicationSet = new Set();
  return (
    array.reduce((duplicates, item) => {
      const value = item[key];
      if (duplicationSet.has(value)) {
        duplicates.push(item);
      } else if (value) {
        duplicationSet.add(value);
      }
      return duplicates;
    }, [])?.length > 0
  );
};

export const getQueryParam = (url, param) => {
  if (!url) return null;
  const queryString = url.split('?')[1];

  if (!queryString) return null;

  const params = new URLSearchParams(queryString);

  return params.get(param);
};

export const updateManifest = (newName: string, icon: string) => {
  const linkTag = document.getElementById('website-manifest');
  if (linkTag && linkTag instanceof HTMLLinkElement) {
    fetch(linkTag.href)
      .then((response) => response.json())
      .then((manifestData) => {
        manifestData.name = newName;
        manifestData.short_name = newName;
        manifestData.icons = [
          {
            src: icon,
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
          },
          {
            src: icon,
            type: 'image/png',
            sizes: '192x192',
            purpose: 'any maskable',
          },
          {
            src: icon,
            type: 'image/png',
            sizes: '512x512',
          },
        ];

        const newManifestBlob = new Blob([JSON.stringify(manifestData)], { type: 'application/json' });
        const newManifestUrl = URL.createObjectURL(newManifestBlob);
        document.getElementById('website-manifest').setAttribute('href', `${newManifestUrl}?${new Date().getTime()}`);
      })
      .catch((error) => console.error('Error updating manifest:', error));
  }
};
export const updateFavicon = (iconUrl: string) => {
  const existingLink = document.querySelector("link[rel*='icon']");
  if (existingLink) {
    existingLink.setAttribute('href', iconUrl);
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'icon');
    link.setAttribute('href', iconUrl);
    document.head.appendChild(link);
  }

  const existingAppleTouchLink = document.querySelector("link[rel='apple-touch-icon']");
  if (existingAppleTouchLink) {
    existingAppleTouchLink.setAttribute('href', iconUrl);
  } else {
    const appleTouchLink = document.createElement('link');
    appleTouchLink.setAttribute('rel', 'apple-touch-icon');
    appleTouchLink.setAttribute('href', iconUrl);
    document.head.appendChild(appleTouchLink);
  }
};
type NestedObject = Record<string, any>;

export const getNestedValue = <T extends NestedObject>(obj: T, path: string): any => {
  if (!path.includes('.')) {
    return obj?.[path];
  }

  return path.split('.').reduce((acc, part) => acc?.[part], obj);
};

import { SVGProps } from 'react';

export const MasterDataIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.667 0h10.666C14.803 0 16 1.217 16 2.712v10.576C16 14.783 14.803 16 13.333 16H2.667C1.197 16 0 14.783 0 13.288V2.712C0 1.217 1.197 0 2.667 0Zm5.866 14.915h4.8c.883 0 1.6-.73 1.6-1.627v-2.44h-6.4v4.067Zm0-5.152h6.4V5.966h-6.4v3.797Zm-7.466 3.525c0 .897.717 1.627 1.6 1.627h4.8v-4.067h-6.4v2.44Zm0-3.525h6.4V5.966h-6.4v3.797Zm0-4.882h13.866v-2.17c0-.896-.717-1.626-1.6-1.626H2.667c-.883 0-1.6.73-1.6 1.627v2.17Z"
      fill="#000"
    />
  </svg>
);

import { USER_TYPES } from '@/constants';
import { useAuthStore } from '@/stores';

export const useIsAdmin = () => {
  const { user } = useAuthStore();
  return user?.role?.user_types_id === USER_TYPES.ADMIN;
};
export const useUserType = () => {
  const { user } = useAuthStore();
  return {
    isAdmin: user?.role?.user_types_id === USER_TYPES.ADMIN,
    isCustomer: user?.role?.user_types_id === USER_TYPES.CUSTOMER,
    isCompany: user?.role?.user_types_id === USER_TYPES.COMPANY,
    user,
  };
};

/* eslint-disable camelcase */
import { create } from 'zustand';
import {
  AddBuisnessModelPayload,
  AddSalesTeamPayload,
  AddTeamMembersPayload,
  ApiResponse,
  ApiResponseWithPagination,
  BasePaginationParams,
  BusinessModelItem,
  ChangeStepPayload,
  CompanySettingsPayload,
  CustomBuisnessModelPayload,
  ProgramDatePayload,
  RoleItem,
  SalesTeamItem,
  SelectedBusinessModelRes,
  TeamMemberTableItem,
} from '@/types';
import { API_END_POINTS, LoadingKeys, WizardPhases, WizardSteps } from '@/constants';
import { handleApiCallInStore } from './utils';
import { customHttp, http } from '@/utils';

interface IWizardStoreState {
  phase: WizardPhases;
  roles: RoleItem[];
  step: WizardSteps;
  totalTeamsMembersNo: number;
  totalSalesTeamNo: number;
  teamMembers: TeamMemberTableItem[];
  salesTeam: SalesTeamItem[];
  setStep: (payload: ChangeStepPayload, isPrev: boolean, onSuccess?: () => void) => void;
  setPhase: (step: number) => void;
  businessModels: BusinessModelItem[];
  getBusinessModels: () => void;
  addBusinessModel: (payload: AddBuisnessModelPayload, onSuccess: () => void) => void;
  addCustomBusinessModel: (payload: CustomBuisnessModelPayload, onSuccess: () => void) => void;
  addTeamMembers: (payload: AddTeamMembersPayload, isSingle: boolean, onSuccess: () => void) => void;
  getAllTeamMembers: (payload: BasePaginationParams) => void;
  updateTeamMember: (memberId: number, role_id: number, onSuccess: () => void) => void;
  getAllRoles: (query?: string) => void;
  deleteTeamMembers: (ids: number[], onSuccess: () => void) => void;
  getSalesTeam: (payload: BasePaginationParams) => void;
  addSalesTeam: (payload: AddSalesTeamPayload, isSingle: boolean, onSuccess: () => void) => void;
  addDistributor: (payload: FormData, onSuccess: () => void) => void;
  getSelectedBusinessModel: (onSuccess: (data: number) => void) => void;
  changeCompanySettingsInWizard: (payload: CompanySettingsPayload, onSuccess: () => void) => void;
}
export const useWizardStore = create<IWizardStoreState>((set) => ({
  step: 0,
  totalTeamsMembersNo: 0,
  totalSalesTeamNo: 0,
  businessModels: [],
  teamMembers: [],
  roles: [],
  salesTeam: [],
  phase: 1,
  changeCompanySettingsInWizard: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.changeCompanySettings, async () => {
      await http.post<ProgramDatePayload>(API_END_POINTS.changeCompanySettings, payload);
      onSuccess();
    });
  },
  getSelectedBusinessModel: (onSuccess) => {
    handleApiCallInStore(LoadingKeys.getSelectedBusinessModel, async () => {
      const { data } = await http.get<undefined, ApiResponse<SelectedBusinessModelRes>>(
        API_END_POINTS.getSelectedBusinessModel,
      );
      onSuccess(data?.business_model?.id);
    });
  },
  addDistributor: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.AddExternalDistributor, async () => {
      await customHttp.post<FormData>(API_END_POINTS.addExternalDistributor, payload);
      onSuccess();
    });
  },
  addSalesTeam: (payload, isSingle, onSuccess) => {
    handleApiCallInStore(isSingle ? LoadingKeys.addSingleSalesTeamMember : LoadingKeys.addSalesTeam, async () => {
      await http.post<AddSalesTeamPayload>(API_END_POINTS.addSalesTeam, payload);
      onSuccess();
    });
  },
  getSalesTeam: (payload) => {
    handleApiCallInStore(LoadingKeys.getSalesTeam, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<SalesTeamItem>>(
        API_END_POINTS.getAllSalesTeam,
        {
          params: payload,
        },
      );
      set({ totalSalesTeamNo: data.total, salesTeam: data.results });
    });
  },
  deleteTeamMembers: (ids, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteTeamMembers, async () => {
      await http.delete<{ ids: number[] }>(API_END_POINTS.deleteTeamMembers, { data: { ids } });
      onSuccess();
    });
  },
  updateTeamMember: (memberId, role_id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.getAllTeamMembers, async () => {
      await http.put<{ role_id: number }>(API_END_POINTS.updateTeamMember(memberId), { role_id });
      onSuccess();
    });
  },
  getAllTeamMembers: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllTeamMembers, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<TeamMemberTableItem>>(
        API_END_POINTS.getAllTeamMembers,
        {
          params: payload,
        },
      );
      set({ totalTeamsMembersNo: data.total, teamMembers: data.results });
    });
  },
  addTeamMembers: (payload, isSingle, onSuccess) => {
    handleApiCallInStore(isSingle ? LoadingKeys.addTeamMember : LoadingKeys.addTeamMembers, async () => {
      await http.post<AddTeamMembersPayload>(API_END_POINTS.addTeamMembers, payload);
      onSuccess();
    });
  },
  getAllRoles: (query) => {
    handleApiCallInStore(LoadingKeys.getAllRoles, async () => {
      const { data } = await http.get<undefined, ApiResponse<RoleItem[]>>(
        `${API_END_POINTS.getAllRoles}${query ?? ''}`,
      );
      set({ roles: data });
    });
  },
  setStep: (payload, isPrev, onSuccess) => {
    handleApiCallInStore(isPrev ? LoadingKeys.setPrevStep : LoadingKeys.setNextStep, async () => {
      await http.put<ChangeStepPayload>(API_END_POINTS.changeStep, payload);
      set({ step: payload?.login_step });
      if (onSuccess) {
        onSuccess();
      }
    });
  },
  setPhase: (phaseNumber) => set({ phase: phaseNumber }),
  getBusinessModels: () => {
    handleApiCallInStore(LoadingKeys.changePassword, async () => {
      const { data } = await http.get<undefined, ApiResponse<BusinessModelItem[]>>(API_END_POINTS.getAllBusinessModels);
      set({ businessModels: data });
    });
  },
  addBusinessModel: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addBusinessModel, async () => {
      await http.post<AddBuisnessModelPayload>(API_END_POINTS.addBusinessModel, payload);
      onSuccess();
    });
  },
  addCustomBusinessModel: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addCustomBusinessModel, async () => {
      await http.post<CustomBuisnessModelPayload>(API_END_POINTS.addCustomBuisnessModel, payload);
      onSuccess();
    });
  },
}));

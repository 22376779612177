import { Button, Flex } from 'antd';
import { FC } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { NotificationsDropdown } from '../components/notifications-dropdown/notifications-dropdown.component';
import { ProfileDropdown } from '../components/profile-dropdown/profile-dropdown.component';
import { ChangeLanguage } from '@/components';
import { useAppStore } from '@/stores';
import { CompanySelect } from '../components/company-select/company-search.component';
import styles from './header.module.scss';
import { useIsAdmin } from '@/hooks';
import DownloadPWA from '../components/download-pwa';

export const DesktopHeader: FC = () => {
  const { sidebarCollapsed, toggleSidebar } = useAppStore();
  const isAdmin = useIsAdmin();

  return (
    <Flex component="nav" align="center" justify="space-between" className={styles.desktopHeader}>
      <Flex flex={1} gap={8} align="center">
        <Button
          className="mirror-rtl"
          type="text"
          icon={sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleSidebar}
          style={{
            fontSize: '16px',
            width: 32,
            height: 32,
          }}
        />
        {isAdmin && <CompanySelect />}
      </Flex>
      <Flex align="center" gap={16}>
        <Flex align="center" gap={4}>
          <DownloadPWA />
          <ChangeLanguage />
          {/* <NotificationsDropdown /> */}
        </Flex>
        <ProfileDropdown />
      </Flex>
    </Flex>
  );
};

import { Badge, Button, Col, Flex, Form, Input, Modal, Row, theme, Tooltip, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormInstance } from 'antd/lib';
import { FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { FormField } from '@/types';
import { TopTableWrapper } from '../top-table-wrapper/top-table-wrapper.component';
import { DynamicForm } from '../dynamic-form/dynamic-form.component';
import { ADDED_INFINITE_NAME, PAGE } from '@/constants';
import { useModal, useOnSearchSubmit } from '@/hooks';
import { RefreshIcon } from '../icons';
import styles from './generic-search-and-filter.module.scss';

const { Title } = Typography;
interface GenericSearchWithFiltersProps {
  fields?: FormField[];
  children?: ReactNode | ReactNode[];
  onReset?: (form: FormInstance) => void;
}
const { useToken } = theme;
export const GenericSearchWithFilters = ({ fields = [], children, onReset }: GenericSearchWithFiltersProps) => {
  const [form] = Form.useForm();
  const { token } = useToken();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openModal, isOpen, closeModal } = useModal();
  const [infiniteSelectFields, setInfiniteSelectFields] = useState<Set<string>>(new Set());
  const [datesFields, setDateFields] = useState<Map<string, string>>(new Map());
  const [searchValue, setSearchValue] = useState<string>();
  const [filterFieldsSet, setFilterFieldsSet] = useState<Set<string>>(new Set());
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<Record<string, any>>();
  const { onSearch } = useOnSearchSubmit();

  useEffect(() => {
    if (initialValues) {
      setSearchValue(initialValues?.keyword);
    }
  }, [initialValues]);

  useEffect(() => {
    if (fields) {
      const finalSet = new Set<string>();
      const dateFieldsMap = new Map<string, string>();

      const filterFields = new Set<string>();

      fields.forEach((item) => {
        if (item.type === 'infiniteSelect') {
          finalSet.add(item.name);
        }
        if (item.type === 'date') {
          dateFieldsMap.set(item.name, item?.datePickerProps?.format as any);
        }
        if (item.name !== 'keyword') {
          filterFields.add(item.name);
        }
      });
      setInfiniteSelectFields(finalSet);
      setFilterFieldsSet(filterFields);
      setDateFields(dateFieldsMap);
    }
  }, []);

  useEffect(() => {
    if (searchParams.size > 0) {
      const searchParamsInitialObj = Object.fromEntries(searchParams.entries());
      const searchParamsObj = {};
      let filtersCountValue = 0;
      Object.entries(searchParamsInitialObj).forEach(([key, value]) => {
        if (filterFieldsSet.has(key)) {
          filtersCountValue += 1;
        }
        if (infiniteSelectFields.has(key)) {
          searchParamsObj[key] = {
            label: searchParamsInitialObj[`${key}${ADDED_INFINITE_NAME}`],
            value: Number(value),
          };
        } else if (datesFields.has(key)) {
          searchParamsObj[key] = searchParamsInitialObj[key] ? dayjs(searchParamsInitialObj[key]) : dayjs();
        } else {
          searchParamsObj[key] = value;
        }
      });

      setFiltersCount(filtersCountValue);
      setInitialValues(searchParamsObj);
    }
  }, [infiniteSelectFields, searchParams]);

  const onFinish = (values) => {
    if (values) {
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (typeof values[key] === 'object' && !datesFields.has(key)) {
            searchParams.set(key, values[key]?.value);
            searchParams.set(`${key}${ADDED_INFINITE_NAME}`, values[key].title);
          } else if (datesFields.has(key)) {
            searchParams.set(key, dayjs(values[key]?.value).format(datesFields.get(key)));
          } else {
            searchParams.set(key, values[key]);
          }
        } else {
          searchParams.delete(key);
        }
      });
      searchParams.set(PAGE, '1');
      setSearchParams(searchParams);
      closeModal();
    }
  };
  const onResetClick = () => {
    form.resetFields();
    onReset?.(form);
    fields.forEach((fieldItem) => {
      if (infiniteSelectFields.has(fieldItem.name)) {
        searchParams.delete(`${fieldItem.name}${ADDED_INFINITE_NAME}`);
      }
      searchParams.delete(fieldItem.name);
    });
    searchParams.delete('keyword');
    setSearchParams(searchParams);
  };

  const handleFilterClick = () => {
    openModal();
  };

  return (
    <TopTableWrapper>
      <Row align="middle" gutter={[10, 10]}>
        <Col span={24} sm={12} lg={8} xl={6}>
          <Input.Search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={styles.searchInput}
            size="middle"
            placeholder={t('search')}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        {fields?.length > 0 && (
          <Col span={24} sm={12} lg={8} xl={6}>
            <Flex gap={10}>
              <Badge count={filtersCount} color={token?.colorPrimary}>
                <Button
                  type={filtersCount > 0 ? 'primary' : 'default'}
                  size="middle"
                  onClick={handleFilterClick}
                  icon={<FilterOutlined />}
                >
                  {t('filter')}
                </Button>
              </Badge>
              <Tooltip title={t('reset')}>
                <Button onClick={onResetClick} icon={<RefreshIcon />} />
              </Tooltip>
            </Flex>
          </Col>
        )}
      </Row>

      <Modal
        className={styles.dynamicModal}
        centered
        title={
          <Flex align="center" gap="8px">
            <Title level={3}>{t('filter')}</Title>
          </Flex>
        }
        open={isOpen}
        onOk={() => form.submit()}
        onCancel={closeModal}
        okText={t('apply')}
        cancelText={t('cancel')}
      >
        <div className={styles.formWrapper}>
          <Form form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
            <DynamicForm initialValues={initialValues} form={form} formFields={fields}>
              {children}
            </DynamicForm>
          </Form>
        </div>
      </Modal>
    </TopTableWrapper>
  );
};

import { SVGProps } from 'react';

export const InvoicesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.333 7.334h-4m1.333 2.667H5.333m5.333-5.334H5.333m8-.133v6.933c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H5.867c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.875-.874c-.217-.427-.217-.988-.217-2.108V4.534c0-1.12 0-1.68.217-2.108a2 2 0 0 1 .875-.874c.427-.218.987-.218 2.108-.218h4.266c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108Z"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

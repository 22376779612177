import { SVGProps } from 'react';

export const MarketingGroupsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 8.4a3.99 3.99 0 0 0 3-1.354 3.99 3.99 0 0 0 3 1.354 3.99 3.99 0 0 0 3-1.354 4 4 0 1 0 0-5.292A3.99 3.99 0 0 0 11.2.4a3.99 3.99 0 0 0-3 1.355 4 4 0 1 0-3 6.646Zm9.563-2.179.021-.042a3.438 3.438 0 0 1-.021.042Zm.021-.042a3 3 0 1 0 0-3.557c.266.536.416 1.14.416 1.778 0 .64-.15 1.243-.416 1.779ZM7.616 2.622a3 3 0 1 0 0 3.557A3.984 3.984 0 0 1 7.2 4.4c0-.639.15-1.242.416-1.778ZM14.2 4.4a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-14 13a7 7 0 0 1 7-7h8a7 7 0 0 1 7 7 3 3 0 0 1-3 3h-16a3 3 0 0 1-3-3Zm7-6h.682a5.994 5.994 0 0 0-2.682 5v1c0 .769.289 1.47.764 2H3.2a2 2 0 0 1-2-2 6 6 0 0 1 6-6Zm-1 6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1a5 5 0 0 0-10 0v1Zm11 0c0 .769-.289 1.47-.764 2H19.2a2 2 0 0 0 2-2 6 6 0 0 0-6-6h-.682a5.994 5.994 0 0 1 2.682 5v1Z"
      fill="#000"
    />
  </svg>
);

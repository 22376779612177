import { SVGProps } from 'react';

export const CustomersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.865 13.266a.533.533 0 1 0 0-1.067.533.533 0 0 0 0 1.067ZM12.778 13.266a.533.533 0 1 0 0-1.067.533.533 0 0 0 0 1.067Z"
      fill="#000"
    />
    <path
      d="M13.559 16H9.074c-.767 0-1.45-.516-1.661-1.254l-.576-2.012a1.717 1.717 0 0 1 .282-1.515c.33-.437.833-.688 1.38-.688h5.635c.547 0 1.05.25 1.38.688.329.436.432.988.281 1.515l-.575 2.012A1.736 1.736 0 0 1 13.559 16Zm-5.06-4.402a.656.656 0 0 0-.528.263.655.655 0 0 0-.107.58l.575 2.012c.08.282.342.48.635.48h4.485a.662.662 0 0 0 .635-.48l.575-2.012a.656.656 0 0 0-.107-.58.655.655 0 0 0-.528-.263H8.5Z"
      fill="#000"
    />
    <path
      d="M13.422 11.597H9.21V9.492c0-1.161.944-2.105 2.105-2.105s2.105.944 2.105 2.105v2.105Zm-3.145-1.066h2.078V9.49a1.04 1.04 0 0 0-1.038-1.038 1.04 1.04 0 0 0-1.039 1.039v1.038ZM5.866 6.4a3.203 3.203 0 0 1-3.2-3.2c0-1.765 1.436-3.2 3.2-3.2 1.765 0 3.2 1.435 3.2 3.2 0 1.765-1.435 3.2-3.2 3.2Zm0-5.333A2.135 2.135 0 0 0 3.733 3.2c0 1.176.957 2.133 2.133 2.133A2.135 2.135 0 0 0 8 3.2a2.135 2.135 0 0 0-2.134-2.133Z"
      fill="#000"
    />
    <path
      d="M1.588 15.956C.648 15.764 0 14.89 0 13.931v-2.555C0 8.446 2.074 5.838 4.97 5.4A5.866 5.866 0 0 1 9.389 6.51c.254.19.29.56.073.794a.528.528 0 0 1-.705.065 4.79 4.79 0 0 0-3.225-.956c-2.544.174-4.465 2.401-4.465 4.952v2.572c0 .47.32.907.784.984a.945.945 0 0 0 1.102-.93v-2.392a.534.534 0 0 1 1.067 0v2.391a2.013 2.013 0 0 1-2.432 1.967Z"
      fill="#000"
    />
    <path d="M6.134 15.998H2.072v-1.066h4.062a.534.534 0 0 1 0 1.066Z" fill="#000" />
  </svg>
);

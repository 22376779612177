import { useEffect, useMemo } from 'react';
import { useWizardStore } from '@/stores';
import { OptionType } from '@/types';

export const useRoles = (query?: string) => {
  const { getAllRoles, roles } = useWizardStore((state) => state);
  useEffect(() => {
    getAllRoles(query);
  }, [query]);

  const rolesOptions: OptionType[] = useMemo(() => {
    if (roles && roles?.length) {
      return roles.map((item) => ({ value: item.id, label: item.name }));
    }
    return [];
  }, [roles]);

  return { rolesOptions, roles };
};

import { SVGProps } from 'react';

export const SalesTeamIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={18} height={18} fill="#000">
      <path fill="#fff" d="M0 0h18v18H0z" />
      <path d="M14.78 9.706h1.378c.464 0 .842.377.842.84v5.611a.843.843 0 0 1-.842.842H14.78a.843.843 0 0 1-.842-.841v-5.611c0-.464.377-.841.842-.841Zm-.242 6.451c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243v-5.61a.243.243 0 0 0-.242-.243H14.78a.243.243 0 0 0-.242.243v5.61Zm-4.07-5.202h1.377c.465 0 .842.377.842.841v4.361a.843.843 0 0 1-.842.842h-1.378a.843.843 0 0 1-.842-.842v-4.36c0-.464.378-.842.842-.842Zm-.243 5.202c0 .134.11.243.242.243h1.378c.134 0 .242-.11.242-.243v-4.36a.243.243 0 0 0-.242-.243h-1.378a.243.243 0 0 0-.242.243v4.36ZM6.155 8.64h1.378c.464 0 .841.378.841.842v6.676a.843.843 0 0 1-.841.842H6.155a.843.843 0 0 1-.842-.842V9.482c0-.464.377-.842.842-.842Zm-.242 7.517c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243V9.481a.243.243 0 0 0-.242-.242H6.155a.243.243 0 0 0-.242.242v6.676ZM1.843 5.51H3.22c.465 0 .842.378.842.842v9.806A.843.843 0 0 1 3.22 17H1.842A.843.843 0 0 1 1 16.158V6.35c0-.463.378-.842.842-.842ZM1.6 16.157c0 .134.11.243.242.243H3.22c.134 0 .242-.11.242-.243V6.351a.243.243 0 0 0-.242-.242H1.842a.243.243 0 0 0-.242.242v9.806ZM15.47 7.49c-.412 0-.772-.218-.975-.544l-2.194.65.001.025a1.147 1.147 0 0 1-2.292 0c0-.1.013-.199.039-.294L7.65 5.936a1.146 1.146 0 0 1-1.885-1.194L3.268 3.02a1.143 1.143 0 0 1-1.883-.874 1.146 1.146 0 0 1 2.291 0c0 .132-.025.26-.068.38L6.106 4.25a1.143 1.143 0 0 1 1.883.874c0 .101-.013.2-.038.295l2.399 1.39c.211-.21.5-.333.806-.333.4 0 .767.215.973.546l2.196-.65-.001-.027c0-.631.514-1.145 1.145-1.145.632 0 1.146.513 1.146 1.145 0 .631-.514 1.145-1.146 1.145ZM2.53 1.6Zm4.313 2.979a.546.546 0 1 0 .001 1.092.546.546 0 0 0 0-1.092Zm4.312 2.496a.547.547 0 0 0-.546.546.547.547 0 1 0 .546-.546Zm4.313-1.276a.547.547 0 1 0 .001 1.094.547.547 0 0 0 0-1.094Z" />
    </mask>
    <path
      d="M14.78 9.706h1.378c.464 0 .842.377.842.84v5.611a.843.843 0 0 1-.842.842H14.78a.843.843 0 0 1-.842-.841v-5.611c0-.464.377-.841.842-.841Zm-.242 6.451c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243v-5.61a.243.243 0 0 0-.242-.243H14.78a.243.243 0 0 0-.242.243v5.61Zm-4.07-5.202h1.377c.465 0 .842.377.842.841v4.361a.843.843 0 0 1-.842.842h-1.378a.843.843 0 0 1-.842-.842v-4.36c0-.464.378-.842.842-.842Zm-.243 5.202c0 .134.11.243.242.243h1.378c.134 0 .242-.11.242-.243v-4.36a.243.243 0 0 0-.242-.243h-1.378a.243.243 0 0 0-.242.243v4.36ZM6.155 8.64h1.378c.464 0 .841.378.841.842v6.676a.843.843 0 0 1-.841.842H6.155a.843.843 0 0 1-.842-.842V9.482c0-.464.377-.842.842-.842Zm-.242 7.517c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243V9.481a.243.243 0 0 0-.242-.242H6.155a.243.243 0 0 0-.242.242v6.676ZM1.843 5.51H3.22c.465 0 .842.378.842.842v9.806A.843.843 0 0 1 3.22 17H1.842A.843.843 0 0 1 1 16.158V6.35c0-.463.378-.842.842-.842ZM1.6 16.157c0 .134.11.243.242.243H3.22c.134 0 .242-.11.242-.243V6.351a.243.243 0 0 0-.242-.242H1.842a.243.243 0 0 0-.242.242v9.806ZM15.47 7.49c-.412 0-.772-.218-.975-.544l-2.194.65.001.025a1.147 1.147 0 0 1-2.292 0c0-.1.013-.199.039-.294L7.65 5.936a1.146 1.146 0 0 1-1.885-1.194L3.268 3.02a1.143 1.143 0 0 1-1.883-.874 1.146 1.146 0 0 1 2.291 0c0 .132-.025.26-.068.38L6.106 4.25a1.143 1.143 0 0 1 1.883.874c0 .101-.013.2-.038.295l2.399 1.39c.211-.21.5-.333.806-.333.4 0 .767.215.973.546l2.196-.65-.001-.027c0-.631.514-1.145 1.145-1.145.632 0 1.146.513 1.146 1.145 0 .631-.514 1.145-1.146 1.145ZM2.53 1.6Zm4.313 2.979a.546.546 0 1 0 .001 1.092.546.546 0 0 0 0-1.092Zm4.312 2.496a.547.547 0 0 0-.546.546.547.547 0 1 0 .546-.546Zm4.313-1.276a.547.547 0 1 0 .001 1.094.547.547 0 0 0 0-1.094Z"
      fill="#000"
    />
    <path
      d="M14.78 9.706h1.378c.464 0 .842.377.842.84v5.611a.843.843 0 0 1-.842.842H14.78a.843.843 0 0 1-.842-.841v-5.611c0-.464.377-.841.842-.841Zm-.242 6.451c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243v-5.61a.243.243 0 0 0-.242-.243H14.78a.243.243 0 0 0-.242.243v5.61Zm-4.07-5.202h1.377c.465 0 .842.377.842.841v4.361a.843.843 0 0 1-.842.842h-1.378a.843.843 0 0 1-.842-.842v-4.36c0-.464.378-.842.842-.842Zm-.243 5.202c0 .134.11.243.242.243h1.378c.134 0 .242-.11.242-.243v-4.36a.243.243 0 0 0-.242-.243h-1.378a.243.243 0 0 0-.242.243v4.36ZM6.155 8.64h1.378c.464 0 .841.378.841.842v6.676a.843.843 0 0 1-.841.842H6.155a.843.843 0 0 1-.842-.842V9.482c0-.464.377-.842.842-.842Zm-.242 7.517c0 .134.109.243.242.243h1.378c.133 0 .242-.11.242-.243V9.481a.243.243 0 0 0-.242-.242H6.155a.243.243 0 0 0-.242.242v6.676ZM1.843 5.51H3.22c.465 0 .842.378.842.842v9.806A.843.843 0 0 1 3.22 17H1.842A.843.843 0 0 1 1 16.158V6.35c0-.463.378-.842.842-.842ZM1.6 16.157c0 .134.11.243.242.243H3.22c.134 0 .242-.11.242-.243V6.351a.243.243 0 0 0-.242-.242H1.842a.243.243 0 0 0-.242.242v9.806ZM15.47 7.49c-.412 0-.772-.218-.975-.544l-2.194.65.001.025a1.147 1.147 0 0 1-2.292 0c0-.1.013-.199.039-.294L7.65 5.936a1.146 1.146 0 0 1-1.885-1.194L3.268 3.02a1.143 1.143 0 0 1-1.883-.874 1.146 1.146 0 0 1 2.291 0c0 .132-.025.26-.068.38L6.106 4.25a1.143 1.143 0 0 1 1.883.874c0 .101-.013.2-.038.295l2.399 1.39c.211-.21.5-.333.806-.333.4 0 .767.215.973.546l2.196-.65-.001-.027c0-.631.514-1.145 1.145-1.145.632 0 1.146.513 1.146 1.145 0 .631-.514 1.145-1.146 1.145ZM2.53 1.6Zm4.313 2.979a.546.546 0 1 0 .001 1.092.546.546 0 0 0 0-1.092Zm4.312 2.496a.547.547 0 0 0-.546.546.547.547 0 1 0 .546-.546Zm4.313-1.276a.547.547 0 1 0 .001 1.094.547.547 0 0 0 0-1.094Z"
      stroke="#000"
      strokeWidth={0.4}
      mask="url(#a)"
    />
  </svg>
);

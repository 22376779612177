import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { FormField } from '@/types';
import { RULES } from '../rules';
import { REDEEM_REQUEST_STATUS_MAPPER } from '../customer-service-constants';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { useGetInitialData } from '@/hooks';

export const useRedeemRequestFields = (isRedemptionHistory: boolean = false) => {
  const { t } = useTranslation();
  const customersData = useGetInitialData({
    apiEndPoint: API_END_POINTS.customers,
    loadingKey: LoadingKeys.getAllCustomersLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
  });
  const partnersData = useGetInitialData({
    apiEndPoint: API_END_POINTS.partners,
    loadingKey: LoadingKeys.getAllPartnersLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at',
  });
  const changeRequestStatusFields = useMemo(
    (): FormField[] => [
      {
        name: 'reason',
        type: 'textarea',
        placeholder: t('typeTheReason'),
        rules: [RULES.required],
      },
    ],
    [],
  );
  const redeemRequestsTableFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(REDEEM_REQUEST_STATUS_MAPPER)?.map((item) => ({
          label: <Tag color={item.color}>{item.label}</Tag>,
          value: item.value,
        })),
      },
      ...(isRedemptionHistory
        ? []
        : [
            {
              label: t('customer'),
              name: 'customer_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectCustomer'),
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.customers,
                loadingKey: LoadingKeys.getAllCustomersLite,
                initialData: customersData?.initialData,
                initialHasMore: customersData?.hasMore,
                selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
                attachedName: 'customer.customer_code',
              },
            },
          ]),
      {
        label: t('partner'),
        name: 'partner_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectPartner'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.partners,
          loadingKey: LoadingKeys.getAllPartnersLite,
          initialData: partnersData?.initialData,
          initialHasMore: partnersData?.hasMore,
          selectParamValue: 'id,name,created_at',
        },
      },
      {
        label: t('date'),
        name: 'created_at',
        type: 'date',
        placeholder: t('selectDate'),
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
      },
    ],
    [customersData, partnersData],
  );
  return { changeRequestStatusFields, redeemRequestsTableFields };
};

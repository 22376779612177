import { create } from 'zustand';
import {
  AddTicketCommentPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  IAddOrEditTicket,
  IAddOrEditTicketType,
  StringOrNumber,
  TicketComment,
  TicketItem,
  TicketTypeItem,
} from '@/types';
import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { handleApiCallInStore } from './utils';
import { http } from '@/utils';

export const useTicketsStore = useGenericStore<
  TicketItem,
  Partial<IAddOrEditTicket>,
  { ticket_type?: string; priority?: string; status?: string; date?: string }
>(
  {
    getAllEndpoint: API_END_POINTS.tickets,
    deleteEndpoint: API_END_POINTS.ticketsCrud,
    updateEndpoint: API_END_POINTS.ticketsCrud,
    getOneEndpoint: API_END_POINTS.ticketsCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllTickets,
    deleteLoadingKey: LoadingKeys.deleteTicket,
    getOneLoadingKey: LoadingKeys.getTicketDetails,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateTicket,
  },
  false,
  true,
);

export const useTicketTypes = useGenericStore<TicketTypeItem, IAddOrEditTicketType>(
  {
    getAllEndpoint: API_END_POINTS.ticketTypes,
    deleteEndpoint: API_END_POINTS.ticketTypesCrud,
    updateEndpoint: API_END_POINTS.ticketTypesCrud,
    getOneEndpoint: API_END_POINTS.ticketTypesCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllTicketTypes,
    deleteLoadingKey: LoadingKeys.deleteTicketType,
    getOneLoadingKey: LoadingKeys.getTicketTypeDetails,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateTicketType,
  },
  false,
);

interface ICommentsStore {
  comments: TicketComment[];
  totalComments: number;
  getAllComments: (ticketId: StringOrNumber, params: BasePaginationParams) => void;
  addComment: (ticketId: StringOrNumber, payload: AddTicketCommentPayload, onSuccess: () => void) => void;
}

export const useCommentsStore = create<ICommentsStore>((set) => ({
  comments: [],
  totalComments: 0,
  addComment: (ticketId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addTicketComment, async () => {
      await http.post<AddTicketCommentPayload>(API_END_POINTS.addTicketComment(ticketId), payload);
      onSuccess();
    });
  },
  getAllComments: (ticketId, params) => {
    handleApiCallInStore(LoadingKeys.getAllTicketComments, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<TicketComment>>(
        API_END_POINTS.getTicketComments(ticketId),
        { params },
      );
      set({ comments: data?.results, totalComments: data?.total });
    });
  },
}));

import { FC } from 'react';
import styles from './Skeleton.module.scss';

interface SkeletonProps {
  className?: string; // Additional custom class names
}

export const Skeleton: FC<SkeletonProps> = ({ className = '' }) => (
  <div className={`${styles.skeleton} ${className}`} />
);

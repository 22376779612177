import { SVGProps } from 'react';

export const PointFormulaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.32 13.794a1.15 1.15 0 0 1-.707-.22c-.266-.194-.68-.694-.42-1.814l.38-1.64c.034-.14-.04-.393-.14-.5L4.107 8.294c-.7-.7-.64-1.307-.547-1.607.093-.3.4-.827 1.373-.993l1.7-.28a.673.673 0 0 0 .387-.287l.94-1.88c.453-.907 1.053-1.04 1.373-1.04.32 0 .92.133 1.374 1.04l.94 1.88c.06.113.26.267.386.287l1.7.28c.974.16 1.28.693 1.374.993.093.3.153.907-.547 1.607L13.233 9.62c-.1.1-.173.36-.14.5l.38 1.64c.26 1.127-.153 1.62-.42 1.814-.266.193-.86.44-1.86-.154L9.6 12.474c-.133-.08-.413-.08-.547 0l-1.593.946c-.46.28-.847.374-1.14.374ZM9.333 3.207c-.106 0-.3.127-.48.487l-.94 1.88c-.2.406-.673.753-1.12.826l-1.7.28c-.38.067-.553.207-.586.314-.034.106.026.32.3.593l1.326 1.327c.34.34.52.96.414 1.426l-.38 1.64c-.114.474-.04.727.033.787.073.053.34.047.753-.207l1.6-.946c.447-.267 1.12-.267 1.56 0l1.594.946c.42.247.686.26.76.207.073-.053.146-.307.033-.787l-.38-1.64c-.107-.473.067-1.086.413-1.426l1.327-1.327c.273-.273.333-.493.3-.593-.033-.1-.207-.247-.587-.314l-1.7-.28c-.446-.073-.92-.42-1.12-.826l-.94-1.88c-.18-.36-.373-.487-.48-.487ZM5.333 3.834h-4a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h4c.274 0 .5.227.5.5s-.226.5-.5.5ZM3.333 13.166h-2a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h2c.274 0 .5.227.5.5s-.226.5-.5.5ZM2 8.5h-.667a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5H2c.273 0 .5.227.5.5s-.227.5-.5.5Z"
      fill="#292D32"
    />
  </svg>
);

import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Affix, Drawer, Layout } from 'antd';
import cn from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { MainContent } from './main-content/main-content.component';
import { MainHeader } from './main-header/main-header.component';
import styles from './main-layout.module.scss';
import { SiderMenu } from './sider/sider-menu';
import { useAppStore } from '@/stores';
import { MAIN_LAYOUT_SIDEBAR_SMALL_WIDTH } from '@/constants/layout-dimensions';
import { useLanguage, useResponsive } from '@/hooks';
import MainSider from './sider/main-sider/main-sider.component';
import { SiderLogo } from './sider/sider-logo/sidebar-logo.component';

export const MainLayout: FC = () => {
  const { sidebarCollapsed, setIsMobileDrawerOpen, isMobileDrawerOpen } = useAppStore((state) => state);
  const { language } = useLanguage();
  const { isTablet } = useResponsive();
  return (
    <Layout>
      {isTablet ? (
        <MainSider />
      ) : (
        <Drawer
          title={
            <div className={styles.mobileMenuHeader}>
              <button className={styles.logoWrapper} type="button" onClick={() => setIsMobileDrawerOpen(false)}>
                <SiderLogo forMobile />
              </button>

              <CloseOutlined onClick={() => setIsMobileDrawerOpen(false)} style={{ cursor: 'pointer' }} />
            </div>
          }
          closable={false}
          className={styles.drawer}
          width={MAIN_LAYOUT_SIDEBAR_SMALL_WIDTH}
          placement={language === 'ar' ? 'right' : 'left'}
          onClose={() => setIsMobileDrawerOpen(false)}
          open={isMobileDrawerOpen}
        >
          <SiderMenu setIsMobileDrawerOpen={setIsMobileDrawerOpen} />
        </Drawer>
      )}

      <Layout
        className={cn(styles.mainLayout, {
          [styles.collpased]: sidebarCollapsed,
        })}
      >
        <Affix offsetTop={0}>
          <MainHeader />
          {/* <JiraButton /> */}
        </Affix>

        <MainContent id="main-content">
          <div>
            <Outlet />
          </div>
        </MainContent>
      </Layout>
    </Layout>
  );
};

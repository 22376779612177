import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Typography } from 'antd';
import { useResponsive } from '@/hooks';
import { useAppStore, useUserStore } from '@/stores';
import { ROUTES } from '@/constants';
import { Logo } from '@/components';
import styles from './sider-logo.module.scss';

const { Title } = Typography;

interface SiderLogoProps {
  forMobile?: boolean;
}

export const SiderLogo: FC<SiderLogoProps> = ({ forMobile = false }) => {
  const { isDesktop } = useResponsive();
  const { sidebarCollapsed } = useAppStore();
  const { companySettings } = useUserStore((state) => state);

  return (
    <div className={`${!forMobile ? styles.siderLogoDiv : ''}`}>
      <Link
        className={cn(styles.siderLogoLink, {
          [styles.smallScreenLink]: !isDesktop && sidebarCollapsed,
        })}
        to={ROUTES.HOME}
      >
        <Logo isSmall={sidebarCollapsed || forMobile} />
        {(!sidebarCollapsed || forMobile) && (
          <Title className={styles.logoTitle} level={5}>
            {companySettings?.company?.name}
          </Title>
        )}
      </Link>
    </div>
  );
};

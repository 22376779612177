import { SVGProps } from 'react';

export const NumberIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.906 6.563h-1.483a.141.141 0 0 0-.141.14v7.974h-.134L4.627 6.623a.142.142 0 0 0-.115-.06H2.953a.141.141 0 0 0-.14.14v10.875c0 .078.063.14.14.14h1.47c.077 0 .14-.063.14-.14V9.495h.134l5.583 8.163c.026.037.07.06.117.06h1.51c.077 0 .14-.063.14-.14V6.703a.141.141 0 0 0-.14-.14Zm8.86 9.68H13.64a.188.188 0 0 0-.188.187v1.125c0 .103.085.187.188.187h7.125a.188.188 0 0 0 .187-.187V16.43a.188.188 0 0 0-.187-.188Zm-3.568-1.477c1.24 0 2.232-.403 2.958-1.212.69-.771 1.032-1.777 1.032-3.019 0-1.244-.343-2.262-1.032-3.03-.724-.816-1.715-1.224-2.958-1.224-1.258 0-2.247.41-2.96 1.238-.684.776-1.017 1.779-1.017 3.016 0 1.228.335 2.231 1.02 3.007.717.813 1.71 1.224 2.957 1.224Zm-1.675-6.18c.396-.484.944-.725 1.673-.725.738 0 1.284.225 1.664.682.384.476.584 1.139.584 1.99 0 .85-.197 1.502-.581 1.966-.387.455-.938.685-1.667.685-.731 0-1.289-.242-1.673-.713-.382-.471-.575-1.108-.575-1.936.003-.839.193-1.476.575-1.95Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);

import { SVGProps } from 'react';

export const EarnAndBurnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M14.667 8h-1.333m-.62 4.715-.943-.943M2.667 8H1.334m2.895-3.772-.943-.942M8 2.667V1.334m3.771 2.895.943-.942M8 14.667v-1.333m-4.714-.62.943-.942M8 4.667l1.03 2.087 2.304.337-1.667 1.623.393 2.293L8 9.924l-2.06 1.083.393-2.293-1.666-1.623 2.303-.337L8 4.667Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

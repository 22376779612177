import { SVGProps } from 'react';

export const HashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.438 9.234a.188.188 0 0 0 .187-.187V7.64a.188.188 0 0 0-.188-.188h-3.843v-3.89a.188.188 0 0 0-.188-.188h-1.5a.188.188 0 0 0-.187.188v3.89H9.375v-3.89a.188.188 0 0 0-.188-.188h-1.5a.188.188 0 0 0-.187.188v3.89H3.562a.188.188 0 0 0-.187.188v1.406c0 .103.084.187.188.187H7.5v5.532H3.562a.188.188 0 0 0-.187.187v1.406c0 .103.084.188.188.188H7.5v3.89c0 .104.084.188.188.188h1.5a.188.188 0 0 0 .187-.188v-3.89h5.344v3.89c0 .104.084.188.187.188h1.5a.188.188 0 0 0 .188-.188v-3.89h3.843a.188.188 0 0 0 .188-.188v-1.406a.188.188 0 0 0-.188-.187h-3.843V9.234h3.843Zm-5.72 5.532H9.376V9.234h5.344v5.532Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);

import { SVGProps } from 'react';

export const TeamsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.244 11.758a6.03 6.03 0 0 0-1.728-1.208A3.991 3.991 0 0 0 14 7.44c0-2.216-1.848-4.034-4.064-4a4.002 4.002 0 0 0-2.456 7.11 6.017 6.017 0 0 0-1.728 1.208A5.961 5.961 0 0 0 4 15.836a.16.16 0 0 0 .16.164h1.12a.16.16 0 0 0 .16-.154 4.52 4.52 0 0 1 1.334-3.07A4.532 4.532 0 0 1 10 11.44c1.218 0 2.364.474 3.226 1.336a4.538 4.538 0 0 1 1.334 3.07.16.16 0 0 0 .16.154h1.12a.162.162 0 0 0 .114-.048.16.16 0 0 0 .046-.116 5.947 5.947 0 0 0-1.756-4.078ZM10 10a2.538 2.538 0 0 1-1.81-.75 2.537 2.537 0 0 1-.75-1.836A2.563 2.563 0 0 1 9.974 4.88a2.575 2.575 0 0 1 1.82.732 2.54 2.54 0 0 1 .768 1.828c0 .684-.266 1.326-.75 1.81A2.547 2.547 0 0 1 10 10ZM4.99 7.968a5.127 5.127 0 0 1 .058-1.458.161.161 0 0 0-.09-.176 2.527 2.527 0 0 1-.738-.502 2.55 2.55 0 0 1-.774-1.908 2.547 2.547 0 0 1 .726-1.712 2.546 2.546 0 0 1 1.864-.774 2.557 2.557 0 0 1 2.128 1.176.16.16 0 0 0 .186.064 4.962 4.962 0 0 1 1.106-.248.16.16 0 0 0 .126-.232A4.007 4.007 0 0 0 6.068 0C3.85-.034 2.002 1.784 2.002 3.998c0 1.256.578 2.376 1.484 3.11-.636.294-1.222.7-1.73 1.208A5.948 5.948 0 0 0 0 12.396a.16.16 0 0 0 .16.164h1.122a.16.16 0 0 0 .16-.154 4.52 4.52 0 0 1 1.334-3.07A4.515 4.515 0 0 1 4.87 8.142a.16.16 0 0 0 .12-.174Z"
      fill="#000"
      fillOpacity={0.88}
    />
  </svg>
);

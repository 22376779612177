import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { RULES } from '../rules';
import { FormField } from '@/types';
import { langLabel } from '@/utils';
import { USER_TYPES, USER_TYPES_MAPPER } from '..';
import { useIsAdmin } from '@/hooks';
import { useAuthStore } from '@/stores';

export const useRoleFields = () => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const { user } = useAuthStore((state) => state);

  const finalUserTypes = useMemo(() => {
    if (!isAdmin) {
      return USER_TYPES_MAPPER.filter((item) => item.value !== USER_TYPES.ADMIN);
    }
    if (user.distributor_type === 'internal') {
      return USER_TYPES_MAPPER.filter((item) => item.value !== USER_TYPES.DISTRIBUTOR);
    }
    return USER_TYPES_MAPPER;
  }, [user, isAdmin]);

  const roleFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('name'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterRoleName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('name'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterRoleName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: t('userType'),
        name: 'user_types_id',
        type: 'select',
        placeholder: t('selectUserType'),
        options: finalUserTypes,
      },
    ],
    [],
  );

  return {
    roleFields,
  };
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Tag } from 'antd';
import { FormField, TicketTypeItem } from '@/types';
import { RULES } from '@/constants/rules';
import { FlagIcon } from '@/components';
import { optionalLabel } from '@/utils';
import { useGetInitialData, useUserType } from '@/hooks';
import { API_END_POINTS } from '@/constants/api-end-points';
import { LoadingKeys } from '@/constants/loading-keys';
import styles from './ticket-fields.module.scss';
import { TICKET_PRIORITY_TYPES_MAPPER, TICKET_STATUS_MAPPER } from '@/constants/sales-constants';

export const useTicketsFields = (ticketTypes: TicketTypeItem[] = []) => {
  const { t } = useTranslation();
  const { isCustomer } = useUserType();

  const customersData = useGetInitialData({
    apiEndPoint: API_END_POINTS.customers,
    loadingKey: LoadingKeys.getAllCustomersLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
  });

  const ticketFields = useMemo(
    (): FormField[] => [
      ...(!isCustomer
        ? [
            {
              label: t('customer'),
              name: 'customer_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectCustomer'),
              infiniteSelectProps: {
                disabled: isCustomer,
                apiEndPoint: API_END_POINTS.customers,
                loadingKey: LoadingKeys.getAllCustomersLite,
                initialData: customersData?.initialData,
                initialHasMore: customersData?.hasMore,
                selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
                attachedName: 'customer.customer_code',
              },
            },
          ]
        : []),
      {
        label: t('ticketType'),
        name: 'ticket_type_id',
        type: 'select',
        placeholder: t('selectTicketType'),
        rules: [RULES.required],
        options: ticketTypes?.map((ticketTypeItem) => ({ label: ticketTypeItem.name, value: ticketTypeItem.id })),
      },
      {
        label: t('priority'),
        name: 'priority',
        type: 'select',
        placeholder: t('selectPriority'),
        rules: [RULES.required],
        options: Object.values(TICKET_PRIORITY_TYPES_MAPPER)?.map((item) => ({
          label: (
            <Tag className={styles.ticketTag} bordered icon={<FlagIcon />} color={item?.color}>
              {item?.label}
            </Tag>
          ),
          value: item.value,
        })),
      },
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        rules: [RULES.required],
        options: Object.values(TICKET_STATUS_MAPPER)?.map((item) => ({
          label: <Badge className={styles.badge} color={item.color} text={item.label} />,
          value: item.value,
        })),
      },
      {
        label: t('ticketTitle'),
        name: 'title',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: optionalLabel(t('ticketBody')),
        name: 'content',
        type: 'textarea',
        textareaProps: {
          rows: 3,
        },
      },
    ],
    [customersData, ticketTypes, isCustomer],
  );
  const ticketSearchFields = useMemo(
    (): FormField[] => [
      {
        label: t('ticketType'),
        name: 'ticket_type_id',
        type: 'select',
        placeholder: t('selectTicketType'),
        options: ticketTypes?.map((ticketTypeItem) => ({ label: ticketTypeItem.name, value: ticketTypeItem.id })),
      },
      {
        label: t('priority'),
        name: 'priority',
        type: 'select',
        placeholder: t('selectPriority'),
        options: Object.values(TICKET_PRIORITY_TYPES_MAPPER)?.map((item) => ({
          label: (
            <Tag className={styles.ticketTag} bordered icon={<FlagIcon />} color={item?.color}>
              {item?.label}
            </Tag>
          ),
          value: item.value,
        })),
      },
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(TICKET_STATUS_MAPPER)?.map((item) => ({
          label: <Badge className={styles.badge} color={item.color} text={item.label} />,
          value: item.value,
        })),
      },
    ],
    [ticketTypes],
  );
  const ticketCommentField = useMemo(
    (): FormField[] => [
      {
        name: 'content',
        type: 'textarea',
        rules: [RULES.required],
        textareaProps: {
          rows: 5,
        },
      },
    ],
    [],
  );

  return {
    ticketFields,
    ticketSearchFields,
    ticketCommentField,
  };
};

import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { IAddOrEditInvoice, InvoiceItem } from '@/types';

export const useSalesInvoicesStore = useGenericStore<InvoiceItem, Partial<IAddOrEditInvoice>>(
  {
    getAllEndpoint: API_END_POINTS.invoices,
    deleteEndpoint: API_END_POINTS.invoicesCrud,
    getOneEndpoint: API_END_POINTS.invoicesCrud,
    updateEndpoint: API_END_POINTS.invoicesCrud,
    updateStatusEndPoint: API_END_POINTS.invoicesUpdateStatus,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllInvoices,
    deleteLoadingKey: LoadingKeys.deleteInvoice,
    getOneLoadingKey: LoadingKeys.getInvoiceById,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateInvoice,
  },
  false,
);

export enum PermissionsKeys {
  CALCULATOR = 'calculator',
  TEAMS = 'teams',
  ROLES = 'roles',
  MARKETING_GROUPS = 'marketing_groups',
  TRAINING_MATERIALS = 'training_materials',
  MARKETING_MATERIALS = 'marketing_materials',
  SETTINGS = 'settings',
  POINTS_FORMULA = 'points_formula',
  SALES_TEAM = 'sales_team',
  DISTRIBUTORS = 'distributors',
  MASTER_DATA = 'master_data',
  INVOICES = 'invoices',
  CUSTOMERS = 'customers',
  TICKETS = 'tickets',
  ORDERS = 'orders',
  TIERS = 'tiers',
  BACKLOG = 'backlog',
  REDEMPTION = 'redemption',
  OFFERS = 'offers',
  REDEMPTION_HISTORY = 'redemption_history',
  PARTNERS = 'partners',
  CUSTOMER_REDEEM_REQUESTS = 'redeem_requests',
  BUSINESS_RULES = 'business_rules',
  COMPANIES = 'companies',
  PARTNER_CATEGORIES = 'partner_categories',
  TICKET_TYPES = 'ticket_types',
}

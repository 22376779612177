import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PARAM_RULES, RULES } from '../rules';
import { FormField } from '@/types';
import { useRoles } from '@/hooks';
import { useAuthStore } from '@/stores';

export const useTeamFields = () => {
  const { user } = useAuthStore((state) => state);
  const { rolesOptions } = useRoles(`?user_types_id=${user?.role?.user_types_id}&is_dropdown=true`);
  const { t } = useTranslation();
  const teamFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        placeholder: t('enterTeamMemberName'),
        rules: [RULES.required],
      },
      {
        label: t('role'),
        name: 'role_id',
        type: 'select',
        placeholder: t('selectTeamMemberRole'),
        rules: [RULES.required],
        options: rolesOptions,
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        inputProps: {
          type: 'email',
        },
        placeholder: t('enterTeamMemberEmail'),
        rules: [RULES.required, RULES.email],
      },
      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, PARAM_RULES.exact(9)],
      },
    ],
    [rolesOptions],
  );
  return { teamFields, rolesOptions };
};

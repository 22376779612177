import { SVGProps } from 'react';

export const BacklogsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.292 17.1h13.586a.392.392 0 0 0 .391-.392v-3.462h2.017a.392.392 0 0 0 .391-.392V9a.392.392 0 0 0-.391-.392h-2.017V1.292A.391.391 0 0 0 14.877.9H1.293a.392.392 0 0 0-.392.392V9a.392.392 0 0 0 .392.392h1.806v3.07H1.292a.391.391 0 0 0-.392.392v3.855a.391.391 0 0 0 .392.391Zm.391-15.417h12.803v3.071H1.683v-3.07Zm0 3.854h12.803v3.071H1.683v-3.07Zm2.198 3.855h13.013v3.07H3.881v-3.07Zm-2.198 3.854h12.803v3.07H1.683v-3.07Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.2}
    />
    <path
      d="M16.094 10.926a.392.392 0 0 0-.391-.391h-1.59a.392.392 0 0 0 0 .783h1.59a.392.392 0 0 0 .391-.392Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.2}
    />
  </svg>
);

import { Affix, Flex, Layout, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ChangeLanguage, Logo, Paragraph } from '@/components';
import { MailIcon } from '@/components/icons';
import StepsComp from './components/steps/steps.component';
import styles from './wizard-steps-layput.module.scss';
import { useUserStore } from '@/stores';
import { useResponsive } from '@/hooks';
import { ProfileDropdown } from '../main-layout/main-header/header/components/profile-dropdown/profile-dropdown.component';
import MobileStepsComp from './components/steps/mobile-steps-comp.component';

const { Title } = Typography;

export const WizardStepsLayout = () => {
  const { t } = useTranslation();
  const { companySettings } = useUserStore((state) => state);
  const { isTablet } = useResponsive();
  return (
    <Layout className={styles.layout} hasSider>
      {isTablet && (
        <Sider
          className={styles.sider}
          collapsedWidth="0"
          width="var(--wizard-steps-layout-sider-width)"
          style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
        >
          <div className={styles.innerSiderWrap}>
            <Flex gap={10} align="center">
              <Logo />
              <Title level={5}>{companySettings?.company?.name}</Title>
            </Flex>
            <div className={styles.stepsWrpper}>
              <StepsComp />
            </div>
            <div>
              <Flex justify="space-between" className={styles.siderBottomWrapper}>
                <Paragraph>
                  © {t('LTD')} {new Date().getFullYear()}
                </Paragraph>
                <Paragraph className={styles.email}>
                  <a href="mailto:help@ltd.com">
                    <span className={styles.mailIcon}>
                      <MailIcon />
                    </span>
                    <span>help@ltd.com</span>
                  </a>
                </Paragraph>
              </Flex>
            </div>
          </div>
        </Sider>
      )}

      <Layout
        className={styles.headerContentWrap}
        style={{ marginInlineStart: isTablet ? 'var(--wizard-steps-layout-sider-width)' : 0 }}
      >
        <Affix offsetTop={0}>
          <Header className={styles.header}>
            <div className={styles.logoWrap}>
              <Logo />
            </div>
            <Flex align="center" gap={15} className={styles.profileLangWrap}>
              <ProfileDropdown hideProfile isMobile={!isTablet} />
              <ChangeLanguage />
            </Flex>
          </Header>
        </Affix>

        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

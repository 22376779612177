/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { FormField, InfiniteScrollSelectProps } from '@/types';
import { useFilterMasterDataFields } from './filter-master-data-fields';
import { RULES } from '../rules';
import { useGetInitialData } from '@/hooks';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';

export const useCalculatorFields = () => {
  const { t } = useTranslation();
  const { onReset, masterDataFields } = useFilterMasterDataFields({
    type: 'all',
    isHorizontal: true,
    withLabel: true,
    isItemsEnabled: true,
  });

  const customersData = useGetInitialData({
    apiEndPoint: API_END_POINTS.customers,
    loadingKey: LoadingKeys.getAllCustomersLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
  });

  const marketingGroupsData = useGetInitialData({
    apiEndPoint: API_END_POINTS.marketingGroups,
    loadingKey: LoadingKeys.getAllMarketingGroupsLite,
    fetchOnMount: true,
  });
  const calculatorItemsFields = useMemo(
    (): FormField[] => [
      ...masterDataFields,
      {
        label: t('quantity'),
        name: 'quantity',
        type: 'input',
        placeholder: t('enterTheQuantity'),
        rules: [RULES.required, RULES.integers, RULES.whiteSpace],
        beforeTitle: ' ',
        inputProps: {
          size: 'middle',
        },
        colProps: {
          span: 24,
          sm: 12,
          lg: 8,
        },
      },
    ],
    [masterDataFields],
  );
  const marketingGroupsFormFields = useMemo(
    (): FormField[] => [
      {
        label: t('marketingGroup'),
        name: 'marketing_group_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectMarketingGroup'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.marketingGroups,
          loadingKey: LoadingKeys.getAllMarketingGroupsLite,
          initialData: marketingGroupsData?.initialData,
          initialHasMore: marketingGroupsData?.hasMore,
          size: 'middle',
        },

        colProps: {
          span: 24,
          sm: 12,
          lg: 8,
        },
      },
      {
        label: t('quantity'),
        name: 'quantity',
        type: 'input',
        placeholder: t('enterTheQuantity'),
        rules: [RULES.required],
        beforeTitle: ' ',
        inputProps: {
          size: 'middle',
        },
        colProps: {
          span: 24,
          sm: 12,
          lg: 8,
        },
      },
    ],
    [marketingGroupsData],
  );

  const customerField = useMemo(
    (): FormField => ({
      label: t('customer'),
      name: 'customer_id',
      type: 'infiniteSelect' as const,
      placeholder: t('selectCustomer'),
      colProps: {
        span: 24,
        sm: 12,
        lg: 8,
        xl: 6,
      },
      infiniteSelectProps: {
        apiEndPoint: API_END_POINTS.customers,
        loadingKey: LoadingKeys.getAllCustomersLite,
        initialData: customersData?.initialData,
        initialHasMore: customersData?.hasMore,
        selectParamValue: 'id,name,created_at,customer.customer_code,customer.id',
        size: 'middle',
        attachedName: 'customer.customer_code',
      } as InfiniteScrollSelectProps,
    }),

    [customersData],
  );
  return {
    calculatorItemsFields,
    marketingGroupsFormFields,
    reset: onReset,
    customerField,
  };
};

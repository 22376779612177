import { Outlet } from 'react-router-dom';
import styles from './auth-layout.module.scss';
import { ChangeLanguage } from '@/components';

export const AuthLayout = () => (
  <div className={styles.wrapper}>
    <div className={styles.backgroundWrapper}>
      <div className={styles.languageWrapper}>
        <ChangeLanguage />
      </div>
      <div className={styles.cardWrapper}>
        <Outlet />
      </div>
    </div>
  </div>
);

import { SVGProps } from 'react';

export const RedemptionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.956 10.228h-.758A1.2 1.2 0 0 0 0 11.426v5.07a1.2 1.2 0 0 0 1.198 1.198h7.447c.795 0 1.564-.281 2.173-.793l4.064-3.425a2.032 2.032 0 0 0-1.228-3.602V7.716a1.1 1.1 0 0 0-.015-.154 1.104 1.104 0 0 0 .964-1.092V5.205A1.107 1.107 0 0 0 13.496 4.1h-.189A2.36 2.36 0 0 0 11.403.305a2.32 2.32 0 0 0-1.822.877l-.482.594a1.414 1.414 0 0 0-2.524-.117l-.388-.478A2.32 2.32 0 0 0 4.365.305a2.36 2.36 0 0 0-1.904 3.794h-.347a1.108 1.108 0 0 0-1.106 1.106V6.47a1.105 1.105 0 0 0 .964 1.092c-.01.051-.014.102-.016.154v2.512Zm1.088-2.651h9.523a.144.144 0 0 1 .139.139v2.347c-.055.025-.113.04-.166.07l-4.39 2.524a2.425 2.425 0 0 1-1.207.323H6.32l.02-.78a1.922 1.922 0 0 0-1.921-1.972H2.905V7.716a.145.145 0 0 1 .139-.14Zm11.61 4.202a1.083 1.083 0 0 1-.383.971l-4.065 3.425c-.437.368-.99.57-1.56.57H1.197a.25.25 0 0 1-.25-.25v-5.069a.25.25 0 0 1 .25-.25h3.22a.976.976 0 0 1 .975 1l-.022.804H4.224a.474.474 0 1 0 0 .948h2.719c.59 0 1.169-.155 1.68-.449l4.39-2.524a1.098 1.098 0 0 1 1.04-.03 1.083 1.083 0 0 1 .602.854Zm-4.338-9.998a1.37 1.37 0 0 1 1.087-.528 1.422 1.422 0 1 1 .019 2.846H9.141c.056-.152.085-.312.087-.474v-.502l1.088-1.342ZM7.33 2.36a.474.474 0 1 1 .949 0v1.265a.474.474 0 1 1-.949 0V2.36Zm-4.407.316a1.42 1.42 0 0 1 1.441-1.423 1.368 1.368 0 0 1 1.087.527l.93 1.146v.699c.002.162.032.322.088.474H4.346a1.424 1.424 0 0 1-1.422-1.423Zm-.968 2.53a.158.158 0 0 1 .158-.159h11.382a.158.158 0 0 1 .158.158V6.47a.158.158 0 0 1-.158.158H2.114a.158.158 0 0 1-.158-.158V5.205Z"
      fill="#000"
    />
  </svg>
);

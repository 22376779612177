import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Cookies from 'js-cookie';
import { LanguageType } from '@/types';
import { Dates } from '@/utils';

const localLanguage = i18next.language || Cookies.get('i18next');

export const useLanguage = (): {
  language: LanguageType;
  setLanguage: (locale: LanguageType) => Promise<void>;
  isRTL: boolean;
  isLTR: boolean;
  getKeyValueByLang: (obj: any, key: string) => string;
} => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleChangeLanguage = useCallback(
    async (locale: LanguageType) => {
      Dates.setLocale(locale);
      i18next.changeLanguage(locale);
      window.location.reload();
    },
    [i18n],
  );

  useEffect(() => {
    document.body.dir = localLanguage === 'en' ? 'ltr' : 'rtl';
    document.documentElement.setAttribute('lang', localLanguage);
  }, [localLanguage]);

  return useMemo(
    () => ({
      language: localLanguage as LanguageType,
      setLanguage: handleChangeLanguage,
      isRTL: i18next.language === 'ar',
      isLTR: i18next.language !== 'ar',
      getKeyValueByLang: (obj: any, key: string): string => obj[`${key}_${i18next.language}`],
    }),
    [i18n.language],
  );
};

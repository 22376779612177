import { SVGProps } from 'react';

export const CalculatorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.925 6.047H5v1.075c0 .028.028.05.063.05h.75c.034 0 .062-.022.062-.052V6.047H6.95c.028 0 .05-.028.05-.063v-.75c0-.034-.022-.062-.052-.062H5.875V4.097c0-.028-.028-.05-.063-.05h-.75c-.034 0-.062.022-.062.05v1.075H3.925c-.028 0-.05.028-.05.062v.75c0 .035.022.063.05.063Zm5.125 0h3.025c.028 0 .05-.028.05-.063v-.75c0-.034-.022-.062-.052-.062H9.05c-.028 0-.05.028-.05.062v.75c0 .035.022.063.05.063Zm0 4.14h3.025c.028 0 .05-.028.05-.062v-.75c0-.034-.022-.063-.052-.063H9.05c-.028 0-.05.029-.05.063v.75c0 .034.022.063.05.063Zm0 1.626h3.025c.028 0 .05-.029.05-.063V11c0-.034-.022-.063-.052-.063H9.05c-.028 0-.05.029-.05.063v.75c0 .034.022.063.05.063Zm-3.058-1.266.956-1.17c.068-.082.011-.205-.092-.205h-.793a.113.113 0 0 0-.093.045l-.53.65-.532-.65a.122.122 0 0 0-.092-.045H4.02c-.103 0-.16.123-.092.205l.956 1.17-.98 1.2c-.068.081-.012.205.091.205h.794c.036 0 .07-.016.092-.046l.555-.68.555.68a.122.122 0 0 0 .092.046h.794c.103 0 .159-.124.092-.205l-.977-1.2ZM13.75 1.75H2.25a.5.5 0 0 0-.5.5v11.5a.5.5 0 0 0 .5.5h11.5a.5.5 0 0 0 .5-.5V2.25a.5.5 0 0 0-.5-.5Zm-.563 11.438H2.813V2.812h10.374v10.376Z"
      fill="#000"
      fillOpacity={0.88}
    />
  </svg>
);

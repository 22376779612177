import React, { useState, useEffect, useRef } from 'react';
import { Flex, FormInstance, Select, Spin } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { SelectProps } from 'antd/lib';
import { INITIAL_PAGE_SIZE } from '@/constants';
import { useLoadingStore } from '@/stores';
import { handleApiCallInStore } from '@/stores/utils';
import { ApiResponseWithPagination, InfiniteScrollItem, InfiniteScrollSelectProps } from '@/types';
import { getNestedValue, http } from '@/utils';
import styles from './infinite-scroll-select.module.scss';
import { EnhancedImage } from '../Image/enhanced-image.component';

const { Option } = Select;

interface FetchDataArguments {
  page: number;
  isSearch?: boolean;
  keyword?: string;
  otherParams?: Record<string, any>;
}

export const InfiniteScrollSelect: React.FC<InfiniteScrollSelectProps & SelectProps & { form: FormInstance }> = ({
  apiEndPoint,
  loadingKey,
  selectParamValue = 'id,name_ar,name_en,created_at',
  limit = INITIAL_PAGE_SIZE,
  initialData,
  initialHasMore,
  onSelectChange = () => {},
  onChange,
  form,
  className,
  imgSrcName,
  attachedName,
  ...otherProps
}) => {
  const isFirstTimeCalled = useRef<boolean>(false);
  const [data, setData] = useState<InfiniteScrollItem[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { getLoading } = useLoadingStore((state) => state);

  const fetchData = async ({ page, isSearch = false, keyword, otherParams = {} }: FetchDataArguments) => {
    handleApiCallInStore(loadingKey, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<InfiniteScrollItem>>(apiEndPoint, {
        params: {
          page,
          limit,
          fields: selectParamValue,
          ...otherParams,
          ...(keyword && { keyword }),
        },
      });
      setHasMore(data.total_pages !== page);
      if (isSearch) {
        setData(data?.results);
      } else {
        setData((prevData) => [...prevData, ...(data.results ?? [])]);
      }
    });
  };

  useEffect(() => {
    if (Array.isArray(initialData)) {
      setData(initialData);
    }
    if (initialHasMore !== undefined) {
      setHasMore(initialHasMore);
    }
  }, [initialData, initialHasMore]);

  useEffect(() => {
    if (!initialData && !isFirstTimeCalled.current) {
      isFirstTimeCalled.current = true;
      fetchData({ page: 1, isSearch: true });
    }
  }, []);

  const debounced = useDebouncedCallback(
    // function
    async (value) => {
      setSearch(value);
      setPage(1);
      await fetchData({
        page: 1,
        isSearch: true,
        keyword: value,
      });
    },
    // delay in ms
    300,
  );

  // Load more data on scroll
  const loadMoreData = async () => {
    if (getLoading(loadingKey) || !hasMore) return;
    const newPage = page + 1;
    setPage(newPage);
    await fetchData({
      page: newPage,
      keyword: search,
    });
  };
  const onScroll = async (event) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      loadMoreData();
      target.scrollTo(0, target.scrollHeight);
    }
  };

  return (
    <Select
      labelInValue
      size="large"
      showSearch
      filterOption={false}
      placeholder="Select an item"
      onSearch={(value) => debounced(value)}
      loading={getLoading(loadingKey)}
      onPopupScroll={onScroll}
      {...(otherProps ?? {})}
      className={className ?? ''}
      onChange={(data, option) => {
        onChange(data, option);
        onSelectChange?.(data?.value, form, option);
      }}
    >
      {data.map((el) => (
        <Option key={el.id} title={el?.name ?? el?.title} value={el.id} element={el}>
          {imgSrcName ? (
            <Flex align="center" gap={5}>
              <EnhancedImage fluid preview={false} src={el?.[imgSrcName]} width={20} height={20} />
              {el?.name ?? el?.title}
            </Flex>
          ) : (
            <>
              {el?.name ?? el?.title}{' '}
              {attachedName && getNestedValue(el, attachedName) ? `  -  ${getNestedValue(el, attachedName)}` : ''}
            </>
          )}
        </Option>
      ))}
      {getLoading(loadingKey) && (
        <Option>
          <Flex className={styles.spinnerWrapper} justify="center">
            <Spin size="small" />
          </Flex>
        </Option>
      )}
    </Select>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, Dropdown, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { EnhancedImage } from '@/components';
import styles from './topic-card.module.scss';
import { PermissionType, TopicItem } from '@/types';
import { PermissionsKeys } from '@/constants/permission-keys';
import { usePermission } from '@/hooks';

const { Title } = Typography;

interface TopicCardProps {
  onClick: () => void;
  data: TopicItem;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  showDescription?: boolean;
  permissionKey: PermissionsKeys;
}

export const TopicCard = ({
  onClick,
  data,
  permissionKey,
  showDescription = true,
  onEditClick,
  onDeleteClick,
}: TopicCardProps) => {
  const { t } = useTranslation();
  const { showComponent } = usePermission();

  const items = [
    ...(showComponent(permissionKey, PermissionType.EDIT)
      ? [
          {
            key: '1',
            label: t('edit'),
            extra: <EditOutlined />,
            onClick: onEditClick,
          },
        ]
      : []),
    ...(showComponent(permissionKey, PermissionType.DELETE)
      ? [
          {
            key: '2',
            label: t('delete'),
            extra: <DeleteOutlined />,
            onClick: onDeleteClick,
          },
        ]
      : []),
  ];
  return (
    <Card
      className={styles.topicCard}
      cover={<EnhancedImage onClick={onClick} preview={false} src={data.image} contain />}
    >
      <Card.Meta
        title={
          <Flex align="center" justify="space-between">
            <Title className={styles.topicTitle} level={5} onClick={onClick}>
              {data?.title}
            </Title>
            {items?.length > 0 && (
              <Dropdown trigger={['click']} menu={{ items }} placement="bottom">
                <div className={styles.dropdownInnerWrap}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            )}
          </Flex>
        }
        description={showDescription ? data?.content : ''}
      />
    </Card>
  );
};

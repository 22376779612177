import { Button, Card, Dropdown, Flex, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { formatNumber, isNullOrUndefined } from '@/utils';
import styles from './dashboard-card.module.scss';

interface DashboardCardProps {
  icon: ReactNode;
  title: string;
  count: number;
  percentage?: number;
  increasing?: boolean;
}
const { Title } = Typography;
export const DashboardCard = ({ icon, title, count = 0, percentage = 0, increasing }: DashboardCardProps) => {
  const { t } = useTranslation();
  const dropdownItems = [
    {
      key: '1',
      label: t('edit'),
      extra: <EditOutlined />,
      onClick: () => {},
    },
    {
      key: '2',
      label: t('delete'),
      extra: <DeleteOutlined />,
      onClick: () => {},
    },
  ];
  return (
    <Card bordered className={styles.dasboardCard}>
      <Flex justify="space-between" align="center" className={styles.dashboardCardHeader} gap={12}>
        <Flex justify="flex-start" align="center" gap={12}>
          <div className={styles.iconWrap}>{icon}</div>
          <Title className={styles.title} level={5}>
            {title}
          </Title>
        </Flex>
        <div>
          <Dropdown menu={{ items: [] }} trigger={['click']}>
            <Button type="text" shape="default" icon={<MoreOutlined />} onClick={(e) => e.preventDefault()} />
          </Dropdown>
        </div>
      </Flex>
      <div className={styles.dashboardCardBody}>
        <Title className={styles.count} level={2}>
          {formatNumber(count)}
        </Title>
        {increasing !== undefined && (
          <div className={styles.increasingWrapper}>
            <span className={styles.increasingIconWrap}>
              {increasing ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            </span>
            <span>{!isNullOrUndefined(percentage) && percentage.toFixed(2)}%</span>
            <span className={styles.increasingDesc}>{t('vsLastMonth')}</span>
          </div>
        )}
      </div>
    </Card>
  );
};

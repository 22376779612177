import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '@/stores';

const DownloadPWA = () => {
  const { t } = useTranslation();
  const { pwaEvent, isPWASupported } = useAppStore((state) => state);

  return (
    <>
      {isPWASupported && (
        <Tooltip placement="top" title={t('downloadApp')}>
          <Button onClick={() => pwaEvent.prompt()} size="middle" icon={<DownloadOutlined />} />
        </Tooltip>
      )}
    </>
  );
};

export default DownloadPWA;

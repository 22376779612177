import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@/types';
import { langLabel } from '@/utils';
import { RULES } from '../rules';

export const useTicketTypesFields = () => {
  const { t } = useTranslation();
  const ticketTypesFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('name'), 'en'),
        name: 'name_en',
        type: 'input',
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('name'), 'ar'),
        name: 'name_ar',
        type: 'input',
        rules: [RULES.required, RULES.arabicOnly],
      },
    ],
    [],
  );
  return {
    ticketTypesFields,
  };
};

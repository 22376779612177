/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { FormField } from '@/types';
import { langLabel, optionalLabel } from '@/utils';
import { RULES, validateNumber } from '../rules';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { useFilterMasterDataFields } from './filter-master-data-fields';

interface ItemFieldsArguments {
  SKUId?: string;
}

export const useItemsFields = ({ SKUId }: ItemFieldsArguments) => {
  const { t } = useTranslation();
  const { onReset, masterDataFields } = useFilterMasterDataFields({
    type: 'items',
    isHorizontal: false,
    withLabel: true,
  });

  const ItemsFields = useMemo(
    (): FormField[] => [
      {
        label: t('SKU'),
        name: 'sku_id',
        type: 'infiniteSelect',
        rules: [RULES.required],
        placeholder: t('selectSKU'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.SKUs,
          loadingKey: LoadingKeys.getAllSKUsLite,
          disabled: !!SKUId,
        },
      },
      {
        label: langLabel(t('itemName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterItemName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('itemName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterItemName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: t('itemCode'),
        name: 'item_code',
        type: 'input',
        rules: [RULES.required, RULES.englishOnly, RULES.whiteSpace],
      },
      {
        label: optionalLabel(t('logo')),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
      {
        label: t('quantity'),
        name: 'quantity',
        type: 'input',
        rules: [RULES.required, RULES.integers, { validator: validateNumber({ minVal: 0 }) }],
        inputNumberProps: {
          min: 1,
        },
      },
      {
        label: t('price'),
        name: 'price',
        type: 'price',
        rules: [RULES.required, RULES.number],
        inputNumberProps: {
          min: 1,
        },
      },
      {
        label: t('images'),
        name: 'images',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 5,
          maxSizeInMB: 1,
        },
      },
    ],
    [SKUId],
  );
  const updateItemPointFormulaFields = useMemo(
    (): FormField[] => [
      {
        label: t('basePercentage'),
        name: 'base_percentage',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, { validator: validateNumber({ minVal: 0 }) }],
        inputNumberProps: {
          addonAfter: '%',
        },
      },
      {
        label: t('points'),
        name: 'points',
        type: 'input',
        rules: [
          RULES.required,
          RULES.number,
          RULES.whiteSpace,
          { validator: validateNumber({ minVal: 1, equality: true }) },
        ],
      },
    ],
    [],
  );
  const tableFields = useMemo((): FormField[] => [...(!SKUId ? masterDataFields : [])], [SKUId, masterDataFields]);
  return {
    ItemsFields,
    tableFields,
    onReset,
    updateItemPointFormulaFields,
  };
};

import { Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useLanguage } from '@/hooks';
import styles from './change-language.module.scss';

export const ChangeLanguage = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Button
      className={styles.changeLangBtn}
      dir={language === 'ar' ? 'ltr' : 'rtl'}
      type="text"
      icon={<GlobalOutlined />}
      onClick={() => setLanguage(language === 'ar' ? 'en' : 'ar')}
    >
      {language === 'ar' ? 'English' : 'عربي'}
    </Button>
  );
};

import { SVGProps } from 'react';

export const DistributorsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.028 17.17h2.35a1.03 1.03 0 0 0 1.029-1.029V14.38a1.03 1.03 0 0 0-1.029-1.028h-.734v-1.322a.147.147 0 0 1 .147-.147h4.847v1.469h-.734a1.03 1.03 0 0 0-1.029 1.028v1.762a1.03 1.03 0 0 0 1.029 1.029h2.35a1.03 1.03 0 0 0 1.028-1.029V14.38a1.03 1.03 0 0 0-1.028-1.028h-.735v-1.47h4.848a.146.146 0 0 1 .146.148v1.322h-.734a1.03 1.03 0 0 0-1.028 1.028v1.762a1.03 1.03 0 0 0 1.028 1.029h2.35a1.029 1.029 0 0 0 1.028-1.029V14.38a1.03 1.03 0 0 0-1.028-1.028h-.734v-1.322A1.029 1.029 0 0 0 13.367 11H8.518V8.944h3.085a1.03 1.03 0 0 0 1.028-1.028V4.124a1.023 1.023 0 0 0-.472-.865L8.635.992a1.033 1.033 0 0 0-1.112 0L3.997 3.26a1.024 1.024 0 0 0-.472.865v3.792a1.03 1.03 0 0 0 1.028 1.028h3.085V11H2.791a1.03 1.03 0 0 0-1.028 1.029v1.322h-.735A1.03 1.03 0 0 0 0 14.379v1.762a1.03 1.03 0 0 0 1.028 1.029Zm8.373-2.791v1.762a.147.147 0 0 1-.147.147h-2.35a.147.147 0 0 1-.147-.147V14.38a.147.147 0 0 1 .147-.147h2.35a.147.147 0 0 1 .147.147Zm5.875 0v1.762a.146.146 0 0 1-.147.147h-2.35a.147.147 0 0 1-.147-.147V14.38a.148.148 0 0 1 .147-.147h2.35a.147.147 0 0 1 .147.147ZM4.406 7.916V4.124A.147.147 0 0 1 4.475 4l3.525-2.266a.145.145 0 0 1 .16 0L11.682 4a.147.147 0 0 1 .068.124v3.792a.147.147 0 0 1-.147.147H9.988V6.447a1.91 1.91 0 1 0-3.819 0v1.616H4.553a.147.147 0 0 1-.146-.147Zm2.644-1.47a1.028 1.028 0 1 1 2.057 0v1.617H7.05V6.447ZM.881 14.38a.147.147 0 0 1 .147-.147h2.35a.147.147 0 0 1 .147.147v1.762a.147.147 0 0 1-.147.147h-2.35a.147.147 0 0 1-.147-.147V14.38Z"
      fill="#000"
    />
  </svg>
);

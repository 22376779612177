import { GlobalToken, theme } from 'antd';
import { forEach, includes, isNumber, isString } from 'lodash';
import { FC, useLayoutEffect } from 'react';

const BASE_FONT_SIZE = 16; // Base font size for converting px to rem

// Determine if a property should use raw numeric values without units
const isUnitlessProperty = (tokenName: string): boolean =>
  includes(tokenName, 'lineHeight') ||
  includes(tokenName, 'opacity') ||
  includes(tokenName, 'Weight') ||
  includes(tokenName, 'motion') ||
  includes(tokenName, 'zIndex');

// Helper to convert px to rem
const pxToRem = (value: number): string => `${value / BASE_FONT_SIZE}rem`;

function saveToken(value: string | number, tokenName: string) {
  const isPrivateField = tokenName.startsWith('_');
  if (isPrivateField) return;

  const variableName = `--antd-${tokenName}`;

  if (isString(value)) {
    document.documentElement.style.setProperty(variableName, value);
  } else if (isNumber(value)) {
    const propertyValue = isUnitlessProperty(tokenName)
      ? `${value}` // Unitless for lineHeight or similar
      : pxToRem(value); // Convert to rem for font sizes, spacing, etc.
    document.documentElement.style.setProperty(variableName, propertyValue);
  }
}

export const CssTokenBridge: FC = () => {
  const { token } = theme.useToken();

  useLayoutEffect(() => {
    forEach(token as GlobalToken, (value, key) => saveToken(value as string | number, key));
  }, [token]);

  return null;
};

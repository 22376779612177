import { useEffect } from 'react';
import { useAppStore } from '@/stores';
import { PWAEvent } from '@/types';

export const usePWA = (): void => {
  const { addDeferredPrompt } = useAppStore();

  useEffect(() => {
    const handler = (e: PWAEvent) => {
      e.preventDefault();
      addDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    // Cleanup listener
    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);
};

import { create } from 'zustand';
import { message } from 'antd';
import { ApiResponse, ApiResponseWithPagination, BasePaginationParams, StringOrNumber } from '@/types';
import { handleApiCallInStore } from './utils';
import { LoadingKeys } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';

export interface ApiEndPoints<ID = number> {
  getAllEndpoint?: string;
  getOneEndpoint?: (id: StringOrNumber) => string;
  createEndpoint?: string;
  updateEndpoint?: (id: StringOrNumber) => string;
  updateStatusEndPoint?: (id: StringOrNumber) => string;
  deleteEndpoint?: (id: ID) => string;
}

export interface MethodsLoadingKeys {
  getAllLoadingKey?: LoadingKeys;
  createOrUpdateLoadingKey?: LoadingKeys;
  deleteLoadingKey?: LoadingKeys;
  getOneLoadingKey?: LoadingKeys;
}

interface CrudOperations<T, TAddOrEdit, OtherParamsType = {}> {
  items: T[];
  total: number;
  itemDetails: T;
  getAll: (params: BasePaginationParams & OtherParamsType, onSuccess?: (items: T[]) => void) => void;
  addOrEdit: (id: StringOrNumber, data: TAddOrEdit, onSuccess: () => void) => void;
  deleteItem: (id: number, onSuccess: () => void, deleteMessage?: string) => void;
  getItemById: (id: StringOrNumber) => void;
  updateStatus: (id: StringOrNumber, data: TAddOrEdit, onSuccess: () => void) => void;
}

type CrudStore<T, TAddOrEdit, OtherParamsType> = CrudOperations<T, TAddOrEdit, OtherParamsType> & {};
// eslint-disable-next-line no-use-before-define
export const useGenericStore = <T extends { id: number }, TAddOrEdit, OtherParamsType = {}>(
  apiEndPoints: ApiEndPoints,
  methodsLoadingKeys: MethodsLoadingKeys,
  isCreateOrUpdateFormData: boolean = false,
  changeLoadingBasedOnId: boolean = false,
) =>
  create<CrudStore<T, TAddOrEdit, OtherParamsType>>((set) => ({
    items: [],
    itemDetails: undefined,
    total: 0,
    getAll: (payload, onSuccess) => {
      handleApiCallInStore(methodsLoadingKeys.getAllLoadingKey, async () => {
        const { data } = await http.get<undefined, ApiResponseWithPagination<T> | ApiResponse<T[]>>(
          apiEndPoints.getAllEndpoint,
          {
            params: payload,
          },
        );
        if ('results' in data) {
          set({ items: data.results, total: data.total });
          onSuccess?.(data.results);
        } else {
          set({ items: data });
          onSuccess?.(data);
        }
      });
    },
    addOrEdit: (id, payload, onSuccess) => {
      handleApiCallInStore(
        (changeLoadingBasedOnId && id
          ? `${methodsLoadingKeys.createOrUpdateLoadingKey}-${id}`
          : methodsLoadingKeys.createOrUpdateLoadingKey) as any,
        async () => {
          const finalPayload = isCreateOrUpdateFormData ? convertToFormData<Partial<TAddOrEdit>>(payload) : payload;
          const httpType = isCreateOrUpdateFormData ? customHttp : http;
          if (id) {
            await httpType.put<FormData | Partial<TAddOrEdit>>(apiEndPoints.updateEndpoint(id), finalPayload);
          } else {
            await httpType.post<Partial<TAddOrEdit> | FormData>(
              apiEndPoints.createEndpoint ?? apiEndPoints.getAllEndpoint,
              finalPayload,
            );
          }
          onSuccess();
        },
      );
    },
    deleteItem: (id, onSuccess, deleteMessage) => {
      handleApiCallInStore(methodsLoadingKeys.deleteLoadingKey, async () => {
        await http.delete(apiEndPoints.deleteEndpoint(id));
        onSuccess();
        if (deleteMessage) {
          message.success(deleteMessage);
        }
      });
    },
    getItemById: (id) => {
      handleApiCallInStore(methodsLoadingKeys.getOneLoadingKey, async () => {
        const { data } = await http.get<undefined, ApiResponse<T>>(apiEndPoints.getOneEndpoint(id));
        set({ itemDetails: data });
      });
    },
    updateStatus: (id, payload, onSuccess) => {
      handleApiCallInStore(methodsLoadingKeys.createOrUpdateLoadingKey, async () => {
        await http.put<FormData | Partial<TAddOrEdit>>(
          apiEndPoints.updateStatusEndPoint ? apiEndPoints.updateStatusEndPoint(id) : apiEndPoints.updateEndpoint(id),
          payload,
        );
        onSuccess();
      });
    },
  }));

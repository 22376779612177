import { DashboardOutlined, GlobalOutlined, SettingOutlined } from '@ant-design/icons';
import { ROUTES } from './routes';
import i18n from '@/i18';
import {
  BacklogsIcon,
  BusinessModelIcon,
  CalculatorIcon,
  CompaniesIcon,
  CustomersIcon,
  DistributorsIcon,
  EarnAndBurnIcon,
  InvoicesIcon,
  MarketingGroupsIcon,
  MarketingMaterialsIcon,
  MasterDataIcon,
  OrdersIcon,
  PartnerCategoriesIcon,
  PartnersIcon,
  PointFormulaIcon,
  RedemptionIcon,
  RedemptionRequestsIcon,
  RolesIcon,
  SalesTeamIcon,
  TeamsIcon,
  TicketsIcon,
  TicketTypeIcon,
  TiersIcon,
  TrainingMaterialsIcon,
} from '@/components';
import { PermissionsKeys } from './permission-keys';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const useSidebarNavigation = () => {
  const sidebarItems: SidebarNavigationItem[] = [
    {
      title: i18n.t('dashboard'),
      key: 'dashboard',
      url: ROUTES.HOME,
      icon: <DashboardOutlined />,
    },

    {
      title: i18n.t('items'),
      key: PermissionsKeys.MASTER_DATA,
      url: ROUTES.ITEMS,
      icon: <MasterDataIcon />,
    },
    {
      title: i18n.t('roles'),
      key: PermissionsKeys.ROLES,
      url: ROUTES.ROLES,
      icon: <RolesIcon />,
    },
    {
      title: i18n.t('teams'),
      key: PermissionsKeys.TEAMS,
      url: ROUTES.TEAMS,
      icon: <TeamsIcon />,
    },
    {
      title: i18n.t('marketingGroups'),
      key: PermissionsKeys.MARKETING_GROUPS,
      url: ROUTES.MARKETING_GROUPS,
      icon: <MarketingGroupsIcon transform="scale(0.8)" />,
    },
    {
      title: i18n.t('distributors'),
      key: PermissionsKeys.DISTRIBUTORS,
      url: ROUTES.DISTRIBUTORS,
      icon: <DistributorsIcon />,
    },
    {
      title: i18n.t('salesTeam'),
      key: PermissionsKeys.SALES_TEAM,
      url: ROUTES.SALES_TEAM,
      icon: <SalesTeamIcon />,
    },
    {
      title: i18n.t('salesInvoices'),
      key: PermissionsKeys.INVOICES,
      url: ROUTES.SALES_INVOICES,
      icon: <InvoicesIcon />,
    },
    {
      title: i18n.t('customers'),
      key: PermissionsKeys.CUSTOMERS,
      url: ROUTES.CUSTOMERS,
      icon: <CustomersIcon />,
    },
    {
      title: i18n.t('customerService'),
      key: PermissionsKeys.TICKETS,
      url: ROUTES.CUSTOMER_SERVICE,
      icon: <TicketsIcon />,
    },
    {
      title: i18n.t('ticketTypes'),
      key: PermissionsKeys.TICKET_TYPES,
      url: ROUTES.TICKET_TYPES,
      icon: <TicketTypeIcon transform="scale(0.8)" />,
    },
    {
      title: i18n.t('orders'),
      key: PermissionsKeys.ORDERS,
      url: ROUTES.ORDERS,
      icon: <OrdersIcon />,
    },
    {
      title: i18n.t('businessModels'),
      key: PermissionsKeys.BUSINESS_RULES,
      url: ROUTES.BUSINESS_MODELS,
      icon: <BusinessModelIcon />,
    },
    {
      title: i18n.t('companies'),
      key: PermissionsKeys.COMPANIES,
      url: ROUTES.COMPANIES,
      icon: <CompaniesIcon />,
    },
    {
      title: i18n.t('marketingMaterials'),
      key: PermissionsKeys.MARKETING_MATERIALS,
      url: ROUTES.MARKETING_MATERIALS,
      icon: <MarketingMaterialsIcon />,
    },
    {
      title: i18n.t('redemption'),
      key: PermissionsKeys.REDEMPTION,
      url: ROUTES.REDEMPTION,
      icon: <RedemptionIcon />,
    },
    {
      title: i18n.t('customerRedeemRequests'),
      key: PermissionsKeys.CUSTOMER_REDEEM_REQUESTS,
      url: ROUTES.CUSTOMER_REDEEM_REQUESTS,
      icon: <RedemptionRequestsIcon />,
    },
    {
      title: i18n.t('trainingMaterials'),
      key: PermissionsKeys.TRAINING_MATERIALS,

      url: ROUTES.TRAINING_MATERIALS,
      icon: <TrainingMaterialsIcon />,
    },
    {
      title: i18n.t('redemptionHistory'),
      key: PermissionsKeys.REDEMPTION_HISTORY,
      url: ROUTES.REDEMPTION_HISTORY,
      icon: <EarnAndBurnIcon />,
    },
    {
      title: i18n.t('partners'),
      key: PermissionsKeys.PARTNERS,
      url: ROUTES.PARTNERS,
      icon: <PartnersIcon />,
    },
    {
      title: i18n.t('partnerCategories'),
      key: PermissionsKeys.PARTNER_CATEGORIES,
      url: ROUTES.PARTNER_CATEGORIES,
      icon: <PartnerCategoriesIcon />,
    },
    {
      title: i18n.t('tiers'),
      key: PermissionsKeys.TIERS,
      url: ROUTES.TIERS,
      icon: <TiersIcon />,
    },
    {
      title: i18n.t('backlogs'),
      key: PermissionsKeys.BACKLOG,
      url: ROUTES.BACKLOGS,
      icon: <BacklogsIcon />,
    },
    {
      title: i18n.t('pointFormula'),
      key: PermissionsKeys.POINTS_FORMULA,
      url: ROUTES.POINT_FORMULA,
      icon: <PointFormulaIcon />,
    },
    {
      title: i18n.t('calculator'),
      key: PermissionsKeys.CALCULATOR,
      url: ROUTES.CALCULATOR,
      icon: <CalculatorIcon />,
    },
    {
      title: i18n.t('settings'),
      key: PermissionsKeys.SETTINGS,
      url: ROUTES.SETTINGS,
      icon: <SettingOutlined />,
    },
    // {
    //   title: i18n.t('addTranslation'),
    //   key: 'addTranslation',
    //   url: ROUTES.ADD_TRANSLATION,
    //   icon: <GlobalOutlined />,
    // },
  ];
  return { sidebarItems };
};

import { SVGProps } from 'react';

export const RolesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.028 6.775H5.972a.454.454 0 0 0-.327.14.482.482 0 0 0 0 .67c.086.089.204.14.327.14h5.056a.455.455 0 0 0 .327-.14.483.483 0 0 0 0-.67.455.455 0 0 0-.327-.14Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.2}
    />
    <path
      d="m11.846 2.643-.002-.005a1.117 1.117 0 0 0-.394-.533 1.057 1.057 0 0 0-.62-.205H6.532a1.056 1.056 0 0 0-.635.195c-.187.131-.329.32-.406.54l-.003.006-.315 1.134H3.307c-.374 0-.733.155-.996.428-.264.274-.411.644-.411 1.03v2.252c.01.383.121.756.322 1.078.19.305.452.553.761.72v2.07c0 1.495 1.01 2.747 2.289 2.747h6.456c1.279 0 2.289-1.252 2.289-2.751v-2.07c.31-.167.572-.415.761-.72.2-.322.312-.695.322-1.077V5.228a1.484 1.484 0 0 0-.412-1.027 1.383 1.383 0 0 0-.995-.427h-1.508l-.34-1.132Zm-.855.28.244.852H6.126l.244-.855a.19.19 0 0 1 .157-.07H10.834a.183.183 0 0 1 .092.02c.025.012.047.03.065.052ZM12.813 9.6a2.44 2.44 0 0 0 .281-.02v1.769c0 1.02-.635 1.801-1.366 1.801H5.272c-.367 0-.707-.194-.958-.52-.25-.325-.408-.778-.408-1.281V9.59c.093.012.187.019.28.02h3.852v.65c0 .125.049.246.135.335a.455.455 0 0 0 .654 0 .483.483 0 0 0 .134-.335v-.66h3.852Zm.525-1.084h-.002c-.16.092-.341.14-.525.142h-3.85v-.283a.482.482 0 0 0-.134-.334.454.454 0 0 0-.654 0 .482.482 0 0 0-.134.334v.275h-3.85c-.184 0-.364-.05-.525-.14l-.002-.001-.519-.286a1.096 1.096 0 0 1-.321-.752V5.222a.519.519 0 0 1 .147-.353.474.474 0 0 1 .338-.144h10.386c.127 0 .25.053.341.148a.52.52 0 0 1 .144.36v2.245a1.097 1.097 0 0 1-.09.416 1.06 1.06 0 0 1-.231.336l-.52.286Z"
      fill="#000"
      stroke="#000"
      strokeWidth={0.2}
    />
  </svg>
);

import { PermissionsKeys } from '@/constants/permission-keys';
import { PermissionType } from './auth';
import { User } from './customers';

export interface AddBuisnessModelPayload {
  business_id: number;
}
export interface CustomBuisnessModelPayload {
  message: string;
}

export type TeamMemberStatus = 'active' | 'inactive';

export interface RoleItem {
  id: number;
  name_ar: string;
  name_en: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  name: string;
  user_types_id: number;
}

export interface TeamMemberItem {
  name: string;
  email: string;
  role_id: number;
}

export interface AddTeamMembersPayload {
  rebuild_data: boolean;
  team_members: TeamMemberItem[];
}
export interface UserRole {
  id: number;
  name_ar: string;
  name_en: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  company_id: number;
  user_types_id: number;
}

export interface SalesTeam {
  id: number;
  name: string;
  email: string;
  mobile: string;
  country_code: string;
  region: string;
  area: string;
  type: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  user_id: number;
  company_id: number;
  distributor_id: any;
}
export interface TeamMemberTableItem {
  id: number;
  name: string;
  email: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  company_id: number;
  role_id: null | number;
  is_active: TeamMemberStatus;
  mobile: string;
  country_code: string;
}
export interface SalesTeamItem {
  id: number;
  name: string;
  email: string;
  logo: any;
  mobile: string;
  country_code: string;
  is_active: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  role_id: number;
  company_id: number;
  role: UserRole;
  sales_team: SalesTeam;
}

export interface AddSalesTeamPayload {
  type: string;
  rebuild_data: boolean;
  sales_team: SalesTeamItem[];
}

export interface AddExternalDistributorPayload {
  name: string;
  email: string;
  mobile: string;
  country_code: string;
  registration_number: string;
  representative_full_name: string;
  logo?: File;
  password: string;
  role?: string;
}

export interface SelectedBusinessModelRes {
  business_model: { id: number; name: string };
}

export enum DistributorType {
  internal = 'internal',
  external = 'external',
}

export interface ProgramDatePayload {
  start_date: string;
  renewal_date: string;
}

export interface ChangeStepPayload {
  login_step?: number;
  last_path?: string;
  distributor_type?: string;
}

export type RolePermissionsType = Record<PermissionsKeys, { permission_name: PermissionType; selected: boolean }[]>;
export interface RolePermissions {
  role: { id: number; name: string };
  permissions: RolePermissionsType;
}

export interface IAddOrEditRole {
  name_ar: string;
  name_en: string;
  user_types_id: number;
}

export interface CompanySettingsPayload {
  start_date: string;
  renewal_date: string;
  base_percentage: string;
  join_points: string;
  credit_per_point: string;
  min_points_to_redeem: string;
}

import { SVGProps } from 'react';

export const CompaniesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={18} height={18} fill="#000">
      <path fill="#fff" d="M0 0h18v18H0z" />
      <path d="M16.7 16.401h-1.068V3.851a.3.3 0 0 0-.246-.295l-4.978-.897V1.3c0-.165-.134-.3-.3-.3H2.668a.3.3 0 0 0-.3.3V16.4H1.3a.3.3 0 1 0 0 .599h15.402a.3.3 0 1 0 0-.599ZM2.967 1.6h6.842v14.803H8.213v-3.67c0-.165-.134-.298-.299-.298H4.861c-.166 0-.3.133-.3.299v3.67H2.968V1.598ZM5.16 16.402v-3.37h2.454v3.37H5.16Zm5.248 0V3.267l4.625.834v12.3h-4.625v.001ZM4.136 5.088h1.41a.3.3 0 0 0 .299-.3V3.38a.3.3 0 0 0-.3-.299H4.137a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299Zm.3-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .298-.3V3.38a.3.3 0 0 0-.299-.299h-1.41a.3.3 0 0 0-.298.3v1.409a.3.3 0 0 0 .299.299Zm.3-1.41h.81v.81h-.81v-.81Zm5.81 4.048h-1.427a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299v-1.41a.3.3 0 0 0-.3-.298Zm-.299 1.409h-.828v-.81h.828v.81Zm.3-4.35h-1.428a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299V5.084a.3.3 0 0 0-.3-.3Zm-.3 1.409h-.828v-.81h.828v.81Zm.3 4.473h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.3h1.426a.3.3 0 0 0 .3-.3v-1.41a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81Zm.3 1.531h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299h1.426a.3.3 0 0 0 .3-.3v-1.409a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81ZM4.136 8.015h1.41a.3.3 0 0 0 .299-.299v-1.41a.3.3 0 0 0-.3-.298H4.137a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.409h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3v-1.41a.3.3 0 0 0-.3-.298H7.23a.3.3 0 0 0-.3.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.41h.81v.81h-.81v-.81Zm-1.983 4.337a.3.3 0 0 0 .3-.3V9.237a.3.3 0 0 0-.3-.3h-1.41a.3.3 0 0 0-.299.3v1.409c0 .165.134.3.3.3h1.409Zm-1.11-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3V9.237a.3.3 0 0 0-.3-.3H7.23a.3.3 0 0 0-.3.3v1.409c0 .165.135.3.3.3Zm.3-1.41h.81v.81h-.81v-.81Z" />
    </mask>
    <path
      d="M16.7 16.401h-1.068V3.851a.3.3 0 0 0-.246-.295l-4.978-.897V1.3c0-.165-.134-.3-.3-.3H2.668a.3.3 0 0 0-.3.3V16.4H1.3a.3.3 0 1 0 0 .599h15.402a.3.3 0 1 0 0-.599ZM2.967 1.6h6.842v14.803H8.213v-3.67c0-.165-.134-.298-.299-.298H4.861c-.166 0-.3.133-.3.299v3.67H2.968V1.598ZM5.16 16.402v-3.37h2.454v3.37H5.16Zm5.248 0V3.267l4.625.834v12.3h-4.625v.001ZM4.136 5.088h1.41a.3.3 0 0 0 .299-.3V3.38a.3.3 0 0 0-.3-.299H4.137a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299Zm.3-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .298-.3V3.38a.3.3 0 0 0-.299-.299h-1.41a.3.3 0 0 0-.298.3v1.409a.3.3 0 0 0 .299.299Zm.3-1.41h.81v.81h-.81v-.81Zm5.81 4.048h-1.427a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299v-1.41a.3.3 0 0 0-.3-.298Zm-.299 1.409h-.828v-.81h.828v.81Zm.3-4.35h-1.428a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299V5.084a.3.3 0 0 0-.3-.3Zm-.3 1.409h-.828v-.81h.828v.81Zm.3 4.473h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.3h1.426a.3.3 0 0 0 .3-.3v-1.41a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81Zm.3 1.531h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299h1.426a.3.3 0 0 0 .3-.3v-1.409a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81ZM4.136 8.015h1.41a.3.3 0 0 0 .299-.299v-1.41a.3.3 0 0 0-.3-.298H4.137a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.409h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3v-1.41a.3.3 0 0 0-.3-.298H7.23a.3.3 0 0 0-.3.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.41h.81v.81h-.81v-.81Zm-1.983 4.337a.3.3 0 0 0 .3-.3V9.237a.3.3 0 0 0-.3-.3h-1.41a.3.3 0 0 0-.299.3v1.409c0 .165.134.3.3.3h1.409Zm-1.11-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3V9.237a.3.3 0 0 0-.3-.3H7.23a.3.3 0 0 0-.3.3v1.409c0 .165.135.3.3.3Zm.3-1.41h.81v.81h-.81v-.81Z"
      fill="#000"
    />
    <path
      d="M16.7 16.401h-1.068V3.851a.3.3 0 0 0-.246-.295l-4.978-.897V1.3c0-.165-.134-.3-.3-.3H2.668a.3.3 0 0 0-.3.3V16.4H1.3a.3.3 0 1 0 0 .599h15.402a.3.3 0 1 0 0-.599ZM2.967 1.6h6.842v14.803H8.213v-3.67c0-.165-.134-.298-.299-.298H4.861c-.166 0-.3.133-.3.299v3.67H2.968V1.598ZM5.16 16.402v-3.37h2.454v3.37H5.16Zm5.248 0V3.267l4.625.834v12.3h-4.625v.001ZM4.136 5.088h1.41a.3.3 0 0 0 .299-.3V3.38a.3.3 0 0 0-.3-.299H4.137a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299Zm.3-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .298-.3V3.38a.3.3 0 0 0-.299-.299h-1.41a.3.3 0 0 0-.298.3v1.409a.3.3 0 0 0 .299.299Zm.3-1.41h.81v.81h-.81v-.81Zm5.81 4.048h-1.427a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299v-1.41a.3.3 0 0 0-.3-.298Zm-.299 1.409h-.828v-.81h.828v.81Zm.3-4.35h-1.428a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298h1.426a.3.3 0 0 0 .3-.299V5.084a.3.3 0 0 0-.3-.3Zm-.3 1.409h-.828v-.81h.828v.81Zm.3 4.473h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.3h1.426a.3.3 0 0 0 .3-.3v-1.41a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81Zm.3 1.531h-1.428a.3.3 0 0 0-.299.3v1.409a.3.3 0 0 0 .3.299h1.426a.3.3 0 0 0 .3-.3v-1.409a.3.3 0 0 0-.3-.299Zm-.3 1.41h-.828v-.81h.828v.81ZM4.136 8.015h1.41a.3.3 0 0 0 .299-.299v-1.41a.3.3 0 0 0-.3-.298H4.137a.3.3 0 0 0-.299.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.409h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3v-1.41a.3.3 0 0 0-.3-.298H7.23a.3.3 0 0 0-.3.299v1.41a.3.3 0 0 0 .3.298Zm.3-1.41h.81v.81h-.81v-.81Zm-1.983 4.337a.3.3 0 0 0 .3-.3V9.237a.3.3 0 0 0-.3-.3h-1.41a.3.3 0 0 0-.299.3v1.409c0 .165.134.3.3.3h1.409Zm-1.11-1.41h.81v.81h-.81v-.81Zm2.793 1.41h1.41a.3.3 0 0 0 .299-.3V9.237a.3.3 0 0 0-.3-.3H7.23a.3.3 0 0 0-.3.3v1.409c0 .165.135.3.3.3Zm.3-1.41h.81v.81h-.81v-.81Z"
      stroke="#000"
      strokeWidth={0.2}
      mask="url(#a)"
    />
  </svg>
);

import { ConfigProvider, theme } from 'antd';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import { appRoutes } from './router/app-router';
import { useLanguage, usePWA } from './hooks';
import { useLoadingStore } from '@/stores/loading-store';
import { useUserStore } from '@/stores/user-store';
import { CssTokenBridge, Loader, LoadingWrap } from './components';
import { LoadingKeys } from './constants/loading-keys';
import './styles/main.scss';
import 'ckeditor5/ckeditor5.css';
import { useCommonStore } from './stores/common-store';
import { useAuthStore } from './stores/auth-store';

const { useToken } = theme;
const defaultMainColor = '#000000';
const defaultSecondaryColor = '#000000';

function App() {
  const { token } = useToken();
  const { getLoading } = useLoadingStore((state) => state);
  const { language } = useLanguage();
  const { companySettings, getCompanyDataByHostName } = useUserStore((state) => state);
  const { getEntities } = useCommonStore((state) => state);
  const { token: userToken } = useAuthStore((state) => state);
  const { getCompanySettings } = useUserStore((state) => state);

  usePWA();

  useEffect(() => {
    if (userToken) {
      getEntities();
      getCompanySettings();
    }
  }, [userToken]);

  useEffect(() => {
    getCompanyDataByHostName();
  }, []);
  const customLocale =
    language === 'ar'
      ? {
          ...arEG,
          DatePicker: {
            ...arEG?.DatePicker,
            lang: {
              ...arEG?.DatePicker?.lang,
              weekdays: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
              shortWeekDays: ['أح', 'إث', 'ث', 'أر', 'خ', 'ج', 'س'],
            },
          },
        }
      : {
          ...enUS,
          DatePicker: {
            ...enUS?.DatePicker,
            lang: {
              ...enUS?.DatePicker?.lang,
              weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            },
          },
        };

  return (
    <div className="App">
      <LoadingWrap loading={getLoading(LoadingKeys.getCompanyDataByHostName)}>
        <ConfigProvider
          locale={customLocale}
          theme={{
            cssVar: false,
            token: {
              colorPrimary: companySettings?.main_color ?? defaultMainColor,
              colorTextHeading: companySettings?.main_color ?? defaultMainColor,
              fontFamily: 'SF Pro Display',
              marginLG: 24,
              paddingLG: 24,
              fontSizeLG: 16,
              fontSize: 16,
              fontSizeSM: 12,
              sizeXS: 6,
            },
            components: {
              Tabs: {
                fontSize: token.fontSizeLG,
                fontWeightStrong: token.fontWeightStrong,
                itemColor: token.colorTextTertiary,
              },
              Menu: {
                itemSelectedBg: token.colorBgTextHover,
                itemHoverBg: token.colorBgTextHover,
                itemActiveBg: token.colorBgTextHover,
                itemHeight: 40,
                itemPaddingInline: 12,
                itemBorderRadius: 6,
                iconMarginInlineEnd: 12,
                itemMarginBlock: 0,
                itemMarginInline: 0,
              },
              Form: {
                labelFontSize: 14,
                labelColor: companySettings?.secondary_color ?? defaultSecondaryColor,
                labelRequiredMarkColor: companySettings?.secondary_color ?? defaultSecondaryColor,
              },
              Layout: {
                siderBg: token.colorBgContainer ?? '#ffffff',
                headerBg: token.colorBgContainer ?? '#ffffff',
              },
              Table: {
                rowSelectedBg: token.colorBgBase,
                rowSelectedHoverBg: token.colorBgBase,
              },
              Input: {
                controlHeight: 32,
                controlHeightLG: 40,
                paddingInlineLG: 11,
                fontSizeLG: 16,
                colorBorder: token.colorBorder,
                fontWeightStrong: 400,
                colorTextPlaceholder: token.colorTextQuaternary,
              },
              Select: {
                optionSelectedBg: '#0000000F',
                controlHeight: 32,
                controlHeightLG: 40,
              },
              Steps: {
                colorBgTextHover: 'red',
              },
              Button: {
                controlHeightLG: 40,
                paddingInline: 16,
                controlHeight: 32,
                paddingInlineLG: 15,
                fontWeight: 500,
                colorLink: token.colorPrimary,
                fontSize: 16,
                contentFontSizeLG: 16,
                colorLinkHover: token.colorPrimary,
              },
            },
            algorithm: theme.compactAlgorithm,
          }}
        >
          <CssTokenBridge />
          <Suspense fallback={<Loader />}>
            <RouterProvider router={appRoutes} />
          </Suspense>
        </ConfigProvider>
      </LoadingWrap>
    </div>
  );
}

export default App;

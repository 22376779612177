import i18next from 'i18next';
import { StringOrNumber } from '@/types';

export const PARAMS_NAMES = {
  categoryId: 'categoryId',
  subcategoryId: 'subcategoryId',
  brandId: 'brandId',
  SKUId: 'SKUId',
  itemId: 'itemId',
  groupId: 'groupId',
  distributorId: 'distributorId',
  topicId: 'topicId',
  articleId: 'articleId',
  materialType: 'materialType',
  ticketId: 'ticketId',
  orderId: 'orderId',
  roleId: 'roleId',
  orderType: 'orderType',
};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  ADMIN_LOGIN: '/login/admin',
  REGISTER: '/register',
  VERIFY_OTP: '/verify-otp',
  FORGOT_PASSWORD: '/forgot-password',
  CEHCK_EMAIL: '/check-email',
  CHANGE_PASSWORD: '/change-password',
  PASSWORD_RESET: '/password-reset',
  SELECT_MODEL: '/select-model',
  CREATE_CUSTOM_MODEL: '/create-custom-model',
  CREATE_CUSTOM_MODEL_SUCCESS: '/create-model-success',
  COMPANY_ROLES: '/company-roles',
  COMPANY_TEAMS: '/company-teams',
  CHOOSE_DISTRIBUTOR: '/choose-distributer',
  COMPANY_DISTRIBUTORS: '/company-distributers',

  ADD_SALES_TEAM: '/add-sales-team',
  ADD_EXTERNAL_DISTRIBUTOR: '/add-external-distributor',
  PROGRAM_DATE: '/program-date',
  COMPLETE_STEPS: '/complete-steps',
  CATEGORIES: '/categories',
  ADD_TRANSLATION: '/add-translation',
  SUBCATEGORIES: `/subcategories`,
  CATEGORY_SUBCATEGORIES: `/categories/:${PARAMS_NAMES.categoryId}/subcategories`,
  SUBCATEGORY_BRANDS: `/categories/:${PARAMS_NAMES.categoryId}/subcategories/:${PARAMS_NAMES.subcategoryId}/brands`,
  BRANDS_SKUS: `/categories/:${PARAMS_NAMES.categoryId}/subcategories/:${PARAMS_NAMES.subcategoryId}/brands/:${PARAMS_NAMES.brandId}`,
  SKU_ITEMS: `/categories/:${PARAMS_NAMES.categoryId}/subcategories/:${PARAMS_NAMES.subcategoryId}/brands/:${PARAMS_NAMES.brandId}/skus/:${PARAMS_NAMES.SKUId}`,
  ITEM_DETAILS: `/items/:${PARAMS_NAMES.itemId}`,
  BRANDS: '/brands',
  SKUS: '/skus',
  ITEMS: '/items',
  ROLES: '/roles',
  TEAMS: '/teams',
  WELCOME: '/welcome',
  MARKETING_GROUPS: '/marketing-groups',
  CALCULATOR: '/calculator',
  TRAINING_MATERIALS: '/manage-training-materials',
  MARKETING_MATERIALS: '/manage-marketing-materials',
  DISTRIBUTORS: '/distributors',
  ADD_DISTRIBUTOR: '/distributors/add',
  EDIT_DISTRIBUTOR: `/distributors/:${PARAMS_NAMES.distributorId}`,
  SALES_TEAM: '/sales-team',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  TRAINING_TOPIC_DETAILS: `/:${PARAMS_NAMES.materialType}/topics/:${PARAMS_NAMES.topicId}`,
  MARKETING_TOPIC_DETAILS: `/:${PARAMS_NAMES.materialType}/topics/:${PARAMS_NAMES.topicId}`,
  ADD_ARTICLE: `/:${PARAMS_NAMES.materialType}/topics/:${PARAMS_NAMES.topicId}/articles/add-article`,
  VIEW_ARTICLE: `/:${PARAMS_NAMES.materialType}/topics/:${PARAMS_NAMES.topicId}/articles/:${PARAMS_NAMES.articleId}/view`,
  EDIT_ARTICLE: `/:${PARAMS_NAMES.materialType}/topics/:${PARAMS_NAMES.topicId}/articles/:${PARAMS_NAMES.articleId}`,
  POINT_FORMULA: '/point-formula',
  ASSIGNED_ITEMS_TO_GROUP: `/marketing-groups/:${PARAMS_NAMES.groupId}`,
  TIERS: '/tiers',
  BACKLOGS: '/backlogs',
  CUSTOMERS: '/customers',
  CUSTOMER_SERVICE: '/customer-service',
  TICKET_DETAILS: `/tickets/:${PARAMS_NAMES.ticketId}`,
  SALES_INVOICES: '/sales-invoices',
  SALES_DASHBOARD: '/sales-dashboard',
  ORDERS: '/orders',
  ORDER_DETAILS: `/orders/:${PARAMS_NAMES.orderType}/:${PARAMS_NAMES.orderId}`,
  REDEMPTION: '/redemption',
  REDEMPTION_HISTORY: '/redemption-history',
  PARTNERS: '/partners',
  PERMISSIONS: `/permissions/:${PARAMS_NAMES.roleId}`,
  CUSTOMER_REDEEM_REQUESTS: '/customer-redeem-requests',
  BUSINESS_MODELS: '/business-models',
  COMPANIES: '/companies',
  PARTNER_CATEGORIES: '/partner-categories',
  COMPANY_SETTINGS: '/company-settings',
  TICKET_TYPES: '/ticket-types',
  NOTFOUND: '*',
} as const;
type RouteValues = (typeof ROUTES)[keyof typeof ROUTES];

type RouteNamesMap = {
  [K in RouteValues]?: string;
};
export const ROUTES_NAMES: RouteNamesMap = {
  [ROUTES.HOME]: i18next.t('dashboard'),
};
export type TopicType = 'training' | 'marketing';
export const PARAM_ROUTES = {
  ITEM_DETAILS: (id: StringOrNumber) => `/items/${id}`,
  TOPIC_DETAILS: (id: StringOrNumber, topicType: TopicType) => `/${topicType}/topics/${id}`,
  CATEGORY_SUBCATEGORIES: (categoryId: StringOrNumber) => `/categories/${categoryId}/subcategories`,
  SUBCATEGORY_BRANDS: (categoryId: StringOrNumber, subcategoryId: StringOrNumber) =>
    `/categories/${categoryId}/subcategories/${subcategoryId}/brands`,
  BRANDS: (subcategoryId: StringOrNumber) => `/brands/${subcategoryId}`,
  BRANDS_SKUs: (categoryId: StringOrNumber, subcategoryId: StringOrNumber, brandId: StringOrNumber) =>
    `/categories/${categoryId}/subcategories/${subcategoryId}/brands/${brandId}`,
  SKU_ITEMS: (
    categoryId: StringOrNumber,
    subcategoryId: StringOrNumber,
    brandId: StringOrNumber,
    SKUId: StringOrNumber,
  ) => `/categories/${categoryId}/subcategories/${subcategoryId}/brands/${brandId}/skus/${SKUId}`,
  ADD_ARTICLE: (id: StringOrNumber, type: 'training' | 'marketing') => `/${type}/topics/${id}/articles/add-article`,
  EDIT_ARTICLE: (topicId: StringOrNumber, articleId: StringOrNumber, type: 'training' | 'marketing') =>
    `/${type}/topics/${topicId}/articles/${articleId}`,
  VIEW_ARTICLE: (topicId: StringOrNumber, articleId: StringOrNumber, type: 'training' | 'marketing') =>
    `/${type}/topics/${topicId}/articles/${articleId}/view`,
  ASSIGNED_ITEMS_TO_MARKETING_GROUP: (groupId: StringOrNumber) => `/marketing-groups/${groupId}`,
  EDIT_DISTRIBUTOR: (distributorId?: StringOrNumber) => `/distributors/${distributorId}`,
  TICKET_DETAILS: (ticketId: StringOrNumber) => `/tickets/${ticketId}`,
  orderDetails: (orderType: 'item' | 'marketing_group', orderId: StringOrNumber) =>
    `${ROUTES.ORDERS}/${orderType}/${orderId}`,
  rolePermissions: (roleId: StringOrNumber) => `/permissions/${roleId}`,
};

import { lazy, Suspense, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { AuthLayout, MainLayout, WizardLayout, WizardStepsLayout } from '@/layouts';
import RequireAuth from './require-auth';
import { useAppStore } from '@/stores';
import AuthRedirect from './auth-redirect';
import { Loader } from '@/components';

const HomePage = lazy(() => import('../pages/dashboard'));
const LoginPage = lazy(() => import('../pages/auth/login'));
const ForgotPassword = lazy(() => import('../pages/auth/forgot-password'));
const CheckEmail = lazy(() => import('../pages/auth/check-email'));
const ChangePassword = lazy(() => import('../pages/auth/change-password'));
const PasswordReset = lazy(() => import('../pages/auth/password-reset'));
const NotFoundPage = lazy(() => import('../pages/not-found'));
const Register = lazy(() => import('../pages/auth/register'));
const VerifyOtp = lazy(() => import('../pages/auth/verify-otp'));
const AdminLogin = lazy(() => import('../pages/auth/admin-login'));

const Welcome = lazy(() => import('../pages/welcome'));
const SelectModel = lazy(() => import('../pages/wizard-steps/step-1/step-1'));
const CreateCustomBusinessModel = lazy(() => import('../pages/wizard-steps/step-1/step-1-1'));
const CreateCustomModelSuccess = lazy(() => import('../pages/wizard-steps/step-1/step-1-2'));

const ChooseDistributor = lazy(() => import('../pages/wizard-steps/step-2/choose-distributor'));
const CompanyDistributors = lazy(() => import('../pages/wizard-steps/step-2/company-distributors'));

const CompanyRoles = lazy(() => import('../pages/wizard-steps/step-3'));
const TeamMembers = lazy(() => import('../pages/wizard-steps/step-4'));
const CompanySettings = lazy(() => import('../pages/wizard-steps/step-5'));

// const AddSalesTeam = lazy(() => import('../pages/wizard-steps/step-4/add-sales-team'));
// const AddYourDistributer = lazy(() => import('../pages/wizard-steps/step-4/add-your-distributor'));
// const ProgramDate = lazy(() => import('../pages/wizard-steps/step-5'));

const CompleteSteps = lazy(() => import('../pages/wizard-steps/complete'));

// pages
const CategoriesPage = lazy(() => import('../pages/master-data/categories'));
const AddTranslation = lazy(() => import('../pages/add-translation'));
const Subcategories = lazy(() => import('../pages/master-data/subcategories'));
const Brands = lazy(() => import('../pages/master-data/brands'));
const SKU = lazy(() => import('../pages/master-data/sku'));
const Items = lazy(() => import('../pages/master-data/items'));
const ItemDetailsPage = lazy(() => import('../pages/master-data/items/details'));
const Roles = lazy(() => import('../pages/teams/team/roles'));
const Teams = lazy(() => import('../pages/teams/team/teams'));
const MarketingGroups = lazy(() => import('../pages/master-data/marketing-groups'));
const AssignedItemsToMarketingGroup = lazy(
  () => import('../pages/master-data/marketing-groups/assigned-items-to-group'),
);

const Calculator = lazy(() => import('../pages/points/calculator'));
const ManageTrainingMaterials = lazy(() => import('../pages/materials/manage-materials'));
const TopicDetails = lazy(() => import('../pages/materials/topic-details'));
const AddOrEditArticle = lazy(() => import('../pages/materials/article/add-or-edit'));
const ArticleView = lazy(() => import('../pages/materials/article/view'));

const Distributors = lazy(() => import('../pages/teams/distributors/list'));
const AddOrEditDistributor = lazy(() => import('../pages/teams/distributors/add-or-edit-distributor'));
const SalesTeam = lazy(() => import('../pages/teams/sales-team'));
const Settings = lazy(() => import('../pages/user/settings'));
const PointFormula = lazy(() => import('../pages/points/point-formula'));
const Profile = lazy(() => import('../pages/user/profile'));
const Tiers = lazy(() => import('../pages/tiers'));
const Customers = lazy(() => import('../pages/customers'));
const CustomerService = lazy(() => import('../pages/tickets/list'));
const TicketDetails = lazy(() => import('../pages/tickets/details'));
const TicketTypes = lazy(() => import('../pages/tickets/ticket-types'));

const SalesInvoices = lazy(() => import('../pages/sales-invoices'));
const Orders = lazy(() => import('../pages/orders'));
const Redemption = lazy(() => import('../pages/redemption'));
const RedemptionHistory = lazy(() => import('../pages/redemption-history'));
const Partners = lazy(() => import('../pages/partners'));
const Permissions = lazy(() => import('../pages/permissions'));
const CustomerRedeemRequests = lazy(() => import('../pages/customer-redeem-request'));
const BusinessModels = lazy(() => import('../pages/business-rules'));
const Companies = lazy(() => import('../pages/companies'));
const PARTNER_CATEGORIES = lazy(() => import('../pages/partner-categories'));
const Backlogs = lazy(() => import('../pages/backlogs'));

const InitialComp = () => {
  const navigate = useNavigate();
  const { setNavigate } = useAppStore((state) => state);

  useEffect(() => {
    setNavigate(navigate);
  }, []);

  return <Outlet />;
};
const LazyRoute = (Component: React.ComponentType) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
);
export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<InitialComp />}>
      <Route path={ROUTES.HOME} element={<MainLayout />}>
        <Route element={<RequireAuth />}>
          <Route index element={LazyRoute(HomePage)} />
          <Route path={ROUTES.CATEGORIES} element={LazyRoute(CategoriesPage)} />
          <Route path={ROUTES.ADD_TRANSLATION} element={LazyRoute(AddTranslation)} />
          <Route path={ROUTES.SUBCATEGORIES} element={LazyRoute(Subcategories)} />
          <Route path={ROUTES.CATEGORY_SUBCATEGORIES} element={LazyRoute(Subcategories)} />
          <Route path={ROUTES.BRANDS} element={LazyRoute(Brands)} />
          <Route path={ROUTES.SUBCATEGORY_BRANDS} element={LazyRoute(Brands)} />
          <Route path={ROUTES.SKUS} element={LazyRoute(SKU)} />
          <Route path={ROUTES.BRANDS_SKUS} element={LazyRoute(SKU)} />
          <Route path={ROUTES.SKU_ITEMS} element={LazyRoute(Items)} />
          <Route path={ROUTES.ITEMS} element={LazyRoute(Items)} />
          <Route path={ROUTES.ITEM_DETAILS} element={LazyRoute(ItemDetailsPage)} />
          <Route path={ROUTES.ROLES} element={LazyRoute(Roles)} />
          <Route path={ROUTES.TEAMS} element={LazyRoute(Teams)} />
          <Route path={ROUTES.MARKETING_GROUPS} element={LazyRoute(MarketingGroups)} />
          <Route path={ROUTES.CALCULATOR} element={LazyRoute(Calculator)} />
          <Route
            path={ROUTES.TRAINING_MATERIALS}
            element={LazyRoute(() => (
              <ManageTrainingMaterials isTraining />
            ))}
          />
          <Route
            path={ROUTES.MARKETING_MATERIALS}
            element={LazyRoute(() => (
              <ManageTrainingMaterials isTraining={false} />
            ))}
          />
          <Route path={ROUTES.DISTRIBUTORS} element={LazyRoute(Distributors)} />
          <Route path={ROUTES.EDIT_DISTRIBUTOR} element={LazyRoute(AddOrEditDistributor)} />
          <Route path={ROUTES.ADD_DISTRIBUTOR} element={LazyRoute(AddOrEditDistributor)} />
          <Route path={ROUTES.TRAINING_TOPIC_DETAILS} element={LazyRoute(TopicDetails)} />
          <Route path={ROUTES.MARKETING_TOPIC_DETAILS} element={LazyRoute(TopicDetails)} />
          <Route path={ROUTES.ADD_ARTICLE} element={LazyRoute(AddOrEditArticle)} />
          <Route path={ROUTES.VIEW_ARTICLE} element={LazyRoute(ArticleView)} />
          <Route path={ROUTES.EDIT_ARTICLE} element={LazyRoute(AddOrEditArticle)} />
          <Route path={ROUTES.POINT_FORMULA} element={LazyRoute(PointFormula)} />
          <Route path={ROUTES.ASSIGNED_ITEMS_TO_GROUP} element={LazyRoute(AssignedItemsToMarketingGroup)} />
          <Route path={ROUTES.SALES_TEAM} element={LazyRoute(SalesTeam)} />
          <Route path={ROUTES.SETTINGS} element={LazyRoute(Settings)} />
          <Route path={ROUTES.PROFILE} element={LazyRoute(Profile)} />
          <Route path={ROUTES.TIERS} element={LazyRoute(Tiers)} />
          <Route path={ROUTES.BACKLOGS} element={LazyRoute(Backlogs)} />
          <Route path={ROUTES.CUSTOMERS} element={LazyRoute(Customers)} />
          <Route path={ROUTES.CUSTOMER_SERVICE} element={LazyRoute(CustomerService)} />
          <Route path={ROUTES.TICKET_DETAILS} element={LazyRoute(TicketDetails)} />
          <Route path={ROUTES.SALES_INVOICES} element={LazyRoute(SalesInvoices)} />
          <Route path={ROUTES.ORDERS} element={LazyRoute(Orders)} />
          <Route path={ROUTES.ORDER_DETAILS} element={LazyRoute(Calculator)} />
          <Route path={ROUTES.REDEMPTION} element={LazyRoute(Redemption)} />
          <Route path={ROUTES.REDEMPTION_HISTORY} element={LazyRoute(RedemptionHistory)} />
          <Route path={ROUTES.PARTNERS} element={LazyRoute(Partners)} />
          <Route path={ROUTES.PERMISSIONS} element={LazyRoute(Permissions)} />
          <Route path={ROUTES.CUSTOMER_REDEEM_REQUESTS} element={LazyRoute(CustomerRedeemRequests)} />
          <Route path={ROUTES.BUSINESS_MODELS} element={LazyRoute(BusinessModels)} />
          <Route path={ROUTES.COMPANIES} element={LazyRoute(Companies)} />
          <Route path={ROUTES.PARTNER_CATEGORIES} element={LazyRoute(PARTNER_CATEGORIES)} />
          <Route path={ROUTES.TICKET_TYPES} element={LazyRoute(TicketTypes)} />
          <Route path={ROUTES.BACKLOGS} element={LazyRoute(Backlogs)} />
        </Route>
      </Route>
      <Route path="/" element={<AuthLayout />}>
        <Route element={<AuthRedirect />}>
          <Route path={ROUTES.LOGIN} element={LazyRoute(LoginPage)} />
          <Route path={ROUTES.ADMIN_LOGIN} element={LazyRoute(AdminLogin)} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={LazyRoute(ForgotPassword)} />
          <Route path={ROUTES.CEHCK_EMAIL} element={LazyRoute(CheckEmail)} />
          <Route path={ROUTES.CHANGE_PASSWORD} element={LazyRoute(ChangePassword)} />
          <Route path={ROUTES.PASSWORD_RESET} element={LazyRoute(PasswordReset)} />
          <Route path={ROUTES.REGISTER} element={LazyRoute(Register)} />
          <Route path={ROUTES.VERIFY_OTP} element={LazyRoute(VerifyOtp)} />
        </Route>
      </Route>
      <Route path="/" element={<WizardLayout />}>
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.WELCOME} element={LazyRoute(Welcome)} />
          <Route path={ROUTES.COMPLETE_STEPS} element={LazyRoute(CompleteSteps)} />
        </Route>
      </Route>
      <Route path="/" element={<WizardStepsLayout />}>
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.SELECT_MODEL} element={LazyRoute(SelectModel)} />
          <Route path={ROUTES.CREATE_CUSTOM_MODEL} element={LazyRoute(CreateCustomBusinessModel)} />
          <Route path={ROUTES.CREATE_CUSTOM_MODEL_SUCCESS} element={LazyRoute(CreateCustomModelSuccess)} />
          <Route path={ROUTES.COMPANY_TEAMS} element={LazyRoute(TeamMembers)} />
          <Route path={ROUTES.COMPANY_ROLES} element={LazyRoute(CompanyRoles)} />
          <Route path={ROUTES.CHOOSE_DISTRIBUTOR} element={LazyRoute(ChooseDistributor)} />
          <Route path={ROUTES.COMPANY_DISTRIBUTORS} element={LazyRoute(CompanyDistributors)} />
          <Route path={ROUTES.COMPANY_SETTINGS} element={LazyRoute(CompanySettings)} />
        </Route>
      </Route>
      <Route path={ROUTES.NOTFOUND} element={<NotFoundPage />} />
    </Route>,
  ),
);

import { SVGProps } from 'react';

export const BusinessModelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.416.266h15.966a.416.416 0 0 1 .416.415v1.33a.416.416 0 0 1-.416.417h-.915v8.898a.415.415 0 0 1-.415.415H8.815v1.914a1.08 1.08 0 1 1-.832 0V11.74H1.746a.416.416 0 0 1-.415-.415V2.428H.416A.416.416 0 0 1 0 2.012V.682A.416.416 0 0 1 .416.265ZM8.399 14.9a.25.25 0 1 0 0-.499.25.25 0 0 0 0 .5ZM2.162 10.91h12.474V2.428H2.162v8.482ZM.832 1.596h15.134v-.499H.832v.5Z"
      fill="#000"
    />
    <path
      d="M13.721 9.745a.415.415 0 0 1-.294-.121L11.06 7.257l-1.037 1.036a.416.416 0 0 1-.588 0L7.734 6.591 6.697 7.628a.416.416 0 0 1-.588 0L3.493 5.01v.992a.416.416 0 1 1-.832 0V4.008a.417.417 0 0 1 .416-.416h1.996a.416.416 0 1 1 0 .831H4.08l2.322 2.323L7.44 5.709a.416.416 0 0 1 .588 0l1.701 1.702 1.037-1.036a.416.416 0 0 1 .588 0l2.661 2.66a.416.416 0 0 1-.294.71Z"
      fill="#000"
    />
  </svg>
);

import { SVGProps } from 'react';

export const PartnerCategoriesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 2.5h-13A.5.5 0 0 0 1 3v10a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5Zm-.625 3.25h-3.313V3.625h3.313V5.75Zm0 3.5h-3.313v-2.5h3.313v2.5Zm-7.438-2.5h3.125v2.5H6.438v-2.5Zm3.125-1H6.438V3.625h3.125V5.75Zm-7.437 1h3.313v2.5H2.124v-2.5Zm0-3.125h3.313V5.75H2.124V3.625Zm0 6.625h3.313v2.125H2.124V10.25Zm4.313 0h3.125v2.125H6.437V10.25Zm7.437 2.125h-3.313V10.25h3.313v2.125Z"
      fill="#000"
      fillOpacity={0.88}
    />
  </svg>
);

import { Flex, Select } from 'antd';
import { FormInstance } from 'antd/lib';
import { countries } from '../../constants/countries';

interface CountriesSelectProps {
  fieldName: string;
  form: FormInstance<any>;
}

const countriesSelectOptions = countries.map((item) => ({
  value: item.dial_code,
  label: (
    <Flex align="center">
      <span className={`fi fi-${item.code.toLowerCase()}`} style={{ marginRight: '8px' }} />
      {item.dial_code}
    </Flex>
  ),
  data: item,
}));
export const CountriesSelect = ({ form, fieldName }: CountriesSelectProps) => (
  <Select
    defaultValue="+966"
    showSearch
    value={form.getFieldValue(fieldName)}
    style={{ width: 120 }}
    filterOption={(input, option) => (option?.value ?? '').includes(input)}
    options={countriesSelectOptions}
    onChange={(value) => form.setFieldValue(fieldName, value)}
  />
);

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import styles from './profile-dropdown.module.scss';
import { ROUTES } from '@/constants';
import { Paragraph } from '@/components';
import { useAuthStore, useUserStore } from '@/stores';

interface ProfileOverlayProps {
  hide: () => void;
  hideProfile?: boolean;
}

export const ProfileOverlay = ({ hide, hideProfile = false }: ProfileOverlayProps) => {
  const { t } = useTranslation();
  const { logout } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const { setCompanySettings } = useUserStore((state) => state);

  const handleLogoutClick = () => {
    logout();
    setCompanySettings(undefined);
  };
  return (
    <div>
      {!hideProfile && (
        <>
          {' '}
          <Paragraph
            className={styles.text}
            onClick={() => {
              hide();
              navigate(ROUTES.PROFILE);
            }}
          >
            <Link to={ROUTES.HOME}>
              <UserOutlined />
              {t('profile')}
            </Link>
          </Paragraph>
          <Divider className={styles.itemsDivider} />
        </>
      )}

      <Paragraph className={styles.text} onClick={hide}>
        <button className="link-btn" type="button" aria-label={t('logout')} onClick={handleLogoutClick}>
          <LogoutOutlined />
          {t('logout')}
        </button>
      </Paragraph>
    </div>
  );
};

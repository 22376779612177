import { Button, Flex, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollSelect, RefreshIcon } from '@/components';
import { useAdminFields } from '@/constants/fields/admin-fields';
import { useAuthStore } from '@/stores';
import styles from './company-search.module.scss';

const { Search } = Input;

interface CompanySelectProps {
  handleCompanySelect?: (companyId: number) => void;
}

export const CompanySelect = ({ handleCompanySelect }: CompanySelectProps) => {
  const { companiesField } = useAdminFields();
  const { setSelectedCompany, selectedCompany, resetSelectedCompany } = useAuthStore((state) => state);
  const { t } = useTranslation();

  const handleSelectCompany = (data: { value: number; label: string }) => {
    setSelectedCompany(data);
    window.location.reload();
    handleCompanySelect?.(data?.value);
  };
  const handleRevokeToAdminClick = () => {
    resetSelectedCompany();
    window.location.reload();
    handleCompanySelect?.(undefined);
  };

  return (
    <Flex align="center" justify={handleCompanySelect ? 'space-between' : 'flex-start'} gap={10}>
      <InfiniteScrollSelect
        className={styles.companySelect}
        form={null}
        placeholder={companiesField.placeholder}
        {...companiesField?.infiniteSelectProps}
        onChange={(data, otherData: { element: { id: number; name: string } }) => {
          handleSelectCompany({ value: otherData?.element?.id, label: otherData?.element?.name });
        }}
        value={selectedCompany}
      />
      {selectedCompany && (
        <Tooltip title={t('returnToAdmin')}>
          <Button onClick={handleRevokeToAdminClick} icon={<RefreshIcon />} />
        </Tooltip>
      )}
    </Flex>
  );
};

export default Search;

import { SVGProps } from 'react';

export const RedemptionRequestsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.03 3.762h-1.698c.376-.555.489-1.365.27-1.995C13.244.743 12.211 0 11.146 0c-.42 0-.823.109-1.2.323-.497.283-1.87 1.614-2.55 2.682C6.714 1.937 5.342.606 4.845.323A2.399 2.399 0 0 0 3.646 0C2.58 0 1.547.743 1.19 1.768c-.219.63-.106 1.439.27 1.993H0v2.981h1.699V16h11.633V6.742h1.698v-2.98ZM10.325.969c.26-.148.537-.223.823-.223.752 0 1.483.531 1.738 1.264.146.42.06 1.007-.2 1.367a.885.885 0 0 1-.753.383l-2.061.002h-2.06c.313-.682 1.839-2.41 2.513-2.793ZM2.86 3.759a.885.885 0 0 1-.753-.382c-.26-.36-.346-.948-.2-1.368.255-.732.986-1.264 1.738-1.264.286 0 .562.075.822.223.675.384 2.2 2.112 2.514 2.793h-2.06L2.86 3.76ZM.757 5.997v-1.49H4.9l2.236.002v1.488H.757Zm1.699.745h4.68v8.512h-4.68V6.742Zm10.119 8.512H7.893V6.742h4.682v8.512Zm1.699-9.257H7.893V4.509l1.999-.002h4.382v1.49Z"
      fill="#000"
    />
  </svg>
);

import { Button, ButtonProps, Checkbox, message, Modal, Select, Table, TableColumnsType, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';
import Cookies from 'js-cookie';
import axios, { AxiosError } from 'axios';
import { ImportIcon } from '../icons';
import { useCommonStore, useLoadingStore } from '@/stores';
import { API_END_POINTS, LoadingKeys, tokenAttr } from '@/constants';
import { InfoTooltip } from '../info-tootlip/info-tooltip.component';
import { ImportEntityType, PermissionType } from '@/types';
import { useModal, usePermission } from '@/hooks';
import { detectHeaderRowCSV, detectHeaderRowExcel } from './utils';
import styles from './import-btn.module.scss';
import { isThereDuplications } from '@/utils';
import { PermissionsKeys } from '@/constants/permission-keys';
import { CustomTable } from '../table';

interface ImportBtnProps {
  modalTitle?: string;
  buttonTitle?: string;
  buttonProps?: ButtonProps;
  onSuccess?: () => void;
  dataType?: ImportEntityType;
  generatePassword?: boolean;
  tooltipText?: string;
  permissionKey?: PermissionsKeys;
}

export function ImportBtn({
  permissionKey,
  buttonProps = {},
  buttonTitle,
  onSuccess,
  modalTitle,
  dataType,
  generatePassword = false,
  tooltipText,
}: ImportBtnProps) {
  const { showComponent } = usePermission();
  const { t } = useTranslation();
  const { getLoading, setLoading } = useLoadingStore((state) => state);
  const { importData, entities } = useCommonStore((state) => state);
  const [rebuildData, setRebuildData] = useState<boolean>(false);
  const { isOpen, openModal, closeModal } = useModal();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dataSource, setDataSource] = useState<{ key: string; matchedKey: string }[]>([]);
  const [dataSourceMap, setDataSourceMap] = useState<Map<string, { key: string; matchedKey: string }>>(new Map());

  const fillDataSource = () => {
    if (entities?.[dataType]) {
      const dataSourceArr = [];
      const dataSourceMapper = new Map();
      entities?.[dataType]?.columns.forEach((item) => {
        dataSourceMapper.set(item, {
          key: item,
          predefinedKey: item,
          matchedKey: undefined,
        });
        dataSourceArr.push({
          key: item,
          predefinedKey: item,
          matchedKey: undefined,
        });
      });
      setDataSourceMap(dataSourceMapper);
      setDataSource(dataSourceArr);
    }
  };
  useEffect(() => {
    fillDataSource();
  }, [dataType, isOpen, entities]);

  const uploadRequest = async ({ onSuccess }: UploadRequestOption) => {
    onSuccess?.('ok');
  };

  const beforeUpload = (file: RcFile) => {
    const fileFormats = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ];
    const isSupportedFormat =
      fileFormats.includes(file.type) ||
      file.name.toLowerCase().endsWith('.csv') ||
      file.name.toLowerCase().endsWith('.xlsx') ||
      file.name.toLowerCase().endsWith('.xls');
    if (!isSupportedFormat) {
      message.error(`${t('pleaseUploadCSVOrExcelFilesOnly')}`);
      return false;
    }
    return true;
  };
  const checkIfKeyExistInFileHeaders = (headers: string[]) => {
    const newDataSourceMap = new Map(dataSourceMap);
    const newSelectedKeys: string[] = [];
    if (newDataSourceMap.size > 0) {
      headers.forEach((item) => {
        const itemValue = newDataSourceMap.get(item);
        if (itemValue) {
          newSelectedKeys.push(item);
          newDataSourceMap.set(item, { ...itemValue, matchedKey: item });
        }
      });

      setDataSource([...newDataSourceMap.values()]);
      setSelectedKeys(newSelectedKeys);
    }
  };

  const handleFileChange = ({ file, fileList: newFileList }: { file: UploadFile; fileList: UploadFile[] }) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    setFileList(newFileList?.length > 1 ? [newFileList[newFileList?.length - 1]] : newFileList);
    const reader = new FileReader();
    let headerRowData = [];

    if (newFileList?.length > 0) {
      if (file.type === 'text/csv' || file.name.toLowerCase().endsWith('.csv')) {
        reader.onload = (e) => {
          const csvData = e.target?.result as string;
          headerRowData = detectHeaderRowCSV(csvData);
          setHeaders(headerRowData);
          checkIfKeyExistInFileHeaders(headerRowData);
        };
        reader.readAsText(file?.originFileObj);
      } else {
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target?.result) {
            headerRowData = detectHeaderRowExcel(e.target?.result as ArrayBuffer);
            setHeaders(headerRowData);
            checkIfKeyExistInFileHeaders(headerRowData);
          }
        };
        reader.readAsArrayBuffer(file?.originFileObj as any);
      }
    } else {
      setHeaders([]);
    }
  };

  const handleMatchChange = (key: string, value: string) => {
    const newDataSource = dataSource.map((item) => {
      if (item.key === key) {
        return { ...item, matchedKey: value };
      }
      return item;
    });
    setDataSource(newDataSource);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys as string[]);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  const columns: TableColumnsType<{ key: string; matchedKey: string }> = [
    {
      title: t('key'),
      dataIndex: 'predefinedKey',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: t('matchedKey'),
      dataIndex: 'matchedKey',
      render: (_, record) => (
        <Select
          style={{ width: 160 }}
          onChange={(value) => handleMatchChange(record.key, value)}
          placeholder={t('selectMatchedKey')}
          value={record?.matchedKey}
          options={headers?.map((item) => ({ label: item, value: item }))}
        />
      ),
    },
  ];

  const handleClose = () => {
    setFileList([]);
    setHeaders([]);
    setSelectedKeys([]);
    setDataSource([]);
    setDataSourceMap(new Map());
    setRebuildData(false);
    closeModal();
  };

  const handleSubmit = () => {
    if (fileList?.length === 0) {
      message.error(t('pleaseUploadFile'));
      return;
    }
    if (selectedKeys?.length === 0) {
      message.error(t('pleaseSelectTheKeys'));
      return;
    }
    if (isThereDuplications(dataSource, 'matchedKey')) {
      message.error(t('thereIsDuplicateMatchedKeys'));
      return;
    }
    for (const item of dataSource) {
      if (selectedKeys?.includes(item.key) && !item.matchedKey) {
        message.error(t('pleaseFillMatchedKeyForAllSelectedKeys'));
        return;
      }
    }

    const headerMappingObj = {};
    dataSource.forEach((item) => {
      if (selectedKeys?.includes(item.key)) {
        headerMappingObj[item.matchedKey] = item.key;
      } else {
        headerMappingObj[item.key] = item.key;
      }
    });

    importData(
      {
        file: fileList?.[0]?.originFileObj,
        type: dataType,
        rebuild_data: +rebuildData,
        genereate_password: +generatePassword,
        header_mapping: JSON.stringify(headerMappingObj),
      },
      () => {
        handleClose();
        onSuccess?.();
      },
    );
  };

  const handleRemove = () => {
    setFileList([]);
    setSelectedKeys([]);
    setHeaders([]);
    fillDataSource();
  };

  const downloadFile = async () => {
    try {
      setLoading(LoadingKeys.downloadTemplate, true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${API_END_POINTS.downloadTemplate(dataType)}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${Cookies.get(tokenAttr)}`,
          },
        },
      );
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = dataType;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.data instanceof Blob) {
          try {
            const errorText = await error.response.data.text();
            const errorJSON = JSON.parse(errorText);
            message.error(errorJSON?.message ?? t('somethingWentWrongWhileDownloadingTheFile'));
          } catch (parsingError) {
            message.error(t('somethingWentWrongWhileDownloadingTheFile'));
          }
        } else {
          // eslint-disable-next-line no-unused-expressions
          error?.response?.status === 404
            ? message.error(t('somethingWentWrongWhileDownloadingTheFile'))
            : message.error(error?.response?.data ?? t('somethingWentWrongWhileDownloadingTheFile'));
        }
      }
    } finally {
      setLoading(LoadingKeys.downloadTemplate, false);
    }
  };

  return (
    <>
      {((permissionKey && entities[dataType] && showComponent(permissionKey, PermissionType.CREATE)) ||
        !permissionKey) && (
        <Button
          onClick={openModal}
          className={styles.importBtn}
          icon={<ImportIcon />}
          type="primary"
          size="large"
          htmlType="button"
          {...buttonProps}
        >
          {buttonTitle ?? t('importData')}

          <InfoTooltip text={tooltipText ?? t('importDataFromCSVOrEXCELFile')} />
        </Button>
      )}

      {isOpen && (
        <Modal
          className={styles.deleteModal}
          centered
          title={modalTitle ?? t('importData')}
          open
          okButtonProps={{
            loading: getLoading(LoadingKeys.importData),
          }}
          onOk={handleSubmit}
          onCancel={handleClose}
          okText={t('submit')}
          cancelText={t('cancel')}
        >
          <div>
            <div className={styles.btnsWrapper}>
              <div className={styles.uploadWrapper}>
                <Upload
                  beforeUpload={beforeUpload}
                  customRequest={uploadRequest}
                  listType="text"
                  fileList={[...fileList]}
                  onRemove={handleRemove}
                  onChange={handleFileChange}
                  accept=".csv, .xlsx"
                >
                  <Button icon={<UploadOutlined />}>{t('uploadCSVExcel')}</Button>
                </Upload>
              </div>
              <Button
                loading={getLoading(LoadingKeys.downloadTemplate)}
                type="primary"
                onClick={downloadFile}
                icon={<DownloadOutlined />}
              >
                {t('downloadTemplate')}
              </Button>
            </div>

            <div>
              <CustomTable
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={dataSource}
                rowKey="key"
                scroll={{ x: true }}
              />
            </div>
            <Checkbox
              className={styles.replaceData}
              checked={rebuildData}
              onChange={(e) => setRebuildData(e.target.checked)}
            >
              {t('replaceData')}
            </Checkbox>
          </div>
        </Modal>
      )}
    </>
  );
}

import { PermissionsKeys } from '@/constants/permission-keys';
import { useAuthStore } from '@/stores';
import { PermissionType } from '@/types';
import { useLanguage } from './use-language';
import { useUserType } from './use-is-admin';

export const usePermission = () => {
  const { isCustomer } = useUserType();
  const { user } = useAuthStore((state) => state);
  const { language } = useLanguage();
  const translateComponent = (key: PermissionsKeys) => user?.permissions_keys?.[key]?.[`name_${language}`];
  const showComponent = (key: PermissionsKeys, type: PermissionType) => {
    if (key === PermissionsKeys.REDEMPTION_HISTORY && isCustomer) {
      return true;
    }
    return !!user?.permissions?.[key]?.includes(type) || (key as string) === 'dashboard';
  };

  return { showComponent, translateComponent };
};

import { Avatar, Badge, Card, Flex, Select, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FlagIcon } from '../icons';
import { Paragraph } from '../paragraph/paragraph.component';
import { LoadingKeys, PARAM_ROUTES } from '@/constants';
import styles from './ticket-card.module.scss';
import { TicketItem } from '@/types';
import { TICKET_PRIORITY_TYPES_MAPPER, TICKET_STATUS_MAPPER, TICKET_STATUS_TYPES } from '@/constants/sales-constants';
import { Dates } from '@/utils';
import { useLoadingStore } from '@/stores';
import { useUserType } from '@/hooks';

const { Title } = Typography;
interface CustomerServiceCardSkeletonProps {
  data: TicketItem;
  hideView?: boolean;
  handleChangeTicketStatus: (status: TICKET_STATUS_TYPES) => void;
  haveEditPermission: boolean;
}
const ticketsStatusOptions = Object.values(TICKET_STATUS_MAPPER)?.map((item) => ({
  label: <Badge className={styles.badge} color={item.color} text={item.label} />,
  value: item.value,
}));
export const TicketCard = ({
  data,
  hideView = false,
  handleChangeTicketStatus,
  haveEditPermission,
}: CustomerServiceCardSkeletonProps) => {
  const { isCustomer } = useUserType();
  const { t } = useTranslation();
  const { getLoading } = useLoadingStore((state) => state);

  return (
    <Card bordered className={styles.card}>
      <Flex wrap gap={10}>
        <Badge
          className={styles.badge}
          color={TICKET_STATUS_MAPPER?.[data?.status]?.color}
          text={<Title level={5}>{`${t('ticket')}# ${data?.id}`}</Title>}
        />
        <Flex justify="space-between" align="center" gap={10} className={styles.ticketTagsWrapper}>
          <Tag className={styles.ticketType}>{data?.ticket_type?.name}</Tag>

          <Flex gap={10} align="center">
            {haveEditPermission && (
              <Select
                disabled={isCustomer}
                className={styles.selectStatus}
                loading={getLoading(`${LoadingKeys.createOrUpdateTicket}-${data?.id}`)}
                size="middle"
                onChange={(value) => handleChangeTicketStatus(value as TICKET_STATUS_TYPES)}
                placeholder={t('selectPriority')}
                value={data?.status}
                options={ticketsStatusOptions}
              />
            )}

            <Tag
              className={styles.ticketTag}
              bordered
              icon={<FlagIcon />}
              color={TICKET_PRIORITY_TYPES_MAPPER?.[data?.priority]?.color}
            >
              {TICKET_PRIORITY_TYPES_MAPPER?.[data?.priority]?.label}
            </Tag>
          </Flex>
        </Flex>
      </Flex>
      <div className={styles.ticketContent}>
        <Title level={5}>{data?.title}</Title>
        <Paragraph className={styles.ticketText}>{data?.content}</Paragraph>
      </div>
      <div className={styles.ticketFooter}>
        <Flex align="center" justify="space-between" gap={10} wrap>
          <Flex gap={8} align="center" wrap>
            <Flex gap={4} align="center">
              <Avatar className={styles.avatar} src={data?.creator?.logo} icon={<UserOutlined />} />
              <Typography className={styles.avatarUser}>{data?.creator?.name}</Typography>
            </Flex>
            <Typography className={styles.postedAt}>
              {t('postedAt')} {data?.created_at && Dates.format(data?.created_at, 'DD-MM-YYYY HH:MM ')}
            </Typography>
          </Flex>
          {!hideView && (
            <Link className={styles.openTicketLink} to={PARAM_ROUTES.TICKET_DETAILS(data?.id)}>
              {t('viewTicket')}
            </Link>
          )}
        </Flex>
      </div>
    </Card>
  );
};

/* eslint-disable react/prop-types */
import { Table } from 'antd';
import { TableProps } from 'antd/lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@/hooks';

export const CustomTable: React.FC<TableProps<any>> = ({ columns, ...otherProps }) => {
  const { t } = useTranslation();
  const { isRTL } = useLanguage();
  const finalColumns = useMemo(() => {
    const updatedColumns = columns.map((item) => {
      if (item?.title === t('actions')) {
        return { ...item, fixed: isRTL ? 'left' : 'right' };
      }
      return item;
    });
    return updatedColumns;
  }, [columns]);
  return <Table {...otherProps} columns={finalColumns as any} scroll={{ x: true }} />;
};

import { Avatar, Badge, Card, Flex, theme, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { EnhancedImage } from '../Image/enhanced-image.component';
import { HashIcon, LocationIcon, NumberIcon, StarIcon, TargetIcon } from '../icons';
import { TableActions } from '../table-actions/table-actions.component';
import { CustomerItem } from '@/types';
import { PermissionsKeys } from '@/constants/permission-keys';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './customers.module.scss';

const { useToken } = theme;
const { Title } = Typography;

interface CustomerCardProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
  data: CustomerItem;
}

export const CustomerCard = ({ onEditClick, onDeleteClick, data }: CustomerCardProps) => {
  const { t } = useTranslation();
  const { token } = useToken();

  return (
    <Card bordered className={styles.card}>
      <Flex className={styles.cardHead} justify="space-between" align="center" gap={10} wrap>
        <Flex align="center" gap={5}>
          <Flex align="center" gap={10}>
            <Avatar src={data?.logo} className={styles.avatar} size={32} icon={<UserOutlined />} />
            <Title className={styles.customerName} level={5}>
              {data?.name}
            </Title>
          </Flex>
          <EnhancedImage
            fluid
            className={styles.badge}
            width={22}
            height={27}
            preview={false}
            src={data?.customer?.tier?.icon}
          />
        </Flex>
        {data?.is_active && (
          <Badge
            className={styles.badge}
            color={data?.is_active === 'active' ? token.colorSuccess : token.colorTextPlaceholder}
            text={data?.is_active === 'active' ? t('active') : t('inActive')}
          />
        )}
      </Flex>
      <div className={styles.customerDetailsWrapper}>
        <ul>
          <li>
            <Tooltip title={t('email')}>
              <MailOutlined />
            </Tooltip>

            {data?.email}
          </li>
          <li>
            <Tooltip title={t('phone')}>
              <PhoneOutlined />
            </Tooltip>
            {data?.country_code ?? ''} {data?.mobile ?? ''}
          </li>
          <li>
            <Tooltip title={t('nationalAddress')}>
              <LocationIcon />
            </Tooltip>

            {data?.customer?.national_address ?? '--'}
          </li>
          <li>
            <Tooltip title={t('availableCredit')}>
              <StarIcon />
            </Tooltip>
            <span style={{fontWeight: "bold"}}>{t('availableCredit')}:</span> {data?.customer?.available_credit ?? 0} {t('sar')}
          </li>
          <li>
            <Tooltip title={t('earn')}>
              <StarIcon />
            </Tooltip>
            <span style={{fontWeight: "bold"}}>{t('earn')}:</span> {data?.customer?.earn ?? 0} {t('points')}
          </li>
          <li>
            <Tooltip title={t('availablePoints')}>
              <StarIcon />
            </Tooltip>
            <span style={{fontWeight: "bold"}}>{t('availablePoints')}:</span> {data?.customer?.available_points ?? 0} {t('points')}
          </li>
          <li>
            <Tooltip title={t('burn')}>
              <StarIcon />
            </Tooltip>
            <span style={{fontWeight: "bold"}}>{t('burn')}:</span>  {data?.customer?.burn ?? 0} {t('points')}
          </li>
          <li>
            <Tooltip title={t('companyName')}>
              <HomeOutlined />
            </Tooltip>

            {data?.customer?.company_name ?? 0}
          </li>
          <li>
            <Tooltip title={t('target')}>
              <TargetIcon transform="scale(0.7)" style={{ marginInlineStart: '-5px' }} />
            </Tooltip>
            {data?.customer?.target ?? 0}
          </li>
          <li>
            <Tooltip title={t('companyRegistrationNumber')}>
              <NumberIcon transform="scale(0.7)" style={{ marginInlineStart: '-5px' }} />
            </Tooltip>
            {data?.customer?.company_reqistration_number ?? '--'}
          </li>
          <li>
            <Tooltip title={t('taxNumber')}>
              <HashIcon transform="scale(0.7)" style={{ marginInlineStart: '-5px' }} />
            </Tooltip>
            {data?.customer?.tax_number ?? '--'}
          </li>
        </ul>
      </div>
      <Flex justify="space-between" align="center" gap={10} className={styles.footer}>
        <Paragraph>{data?.customer?.customer_code}</Paragraph>
        <TableActions
          permissionKey={PermissionsKeys.CUSTOMERS}
          handleEditClick={onEditClick}
          handleDeleteClick={onDeleteClick}
        />
      </Flex>
    </Card>
  );
};
